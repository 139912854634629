import { axiosInstance } from "../utils/common";

export const getAllInvoicingProfileApi = () => {
  return axiosInstance
    .get("v1/invoicingProfiles")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const getAllInvoicingProfilePaginationApi = (page, limit, filterObj) => {
  return axiosInstance
    .post(`v1/invoicingProfiles/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllInvoicingProfileOnlyApi = (page, limit, searchVal) => {
  return axiosInstance
    .get(`v1/invoicingProfiles/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllInvoicingProfileReportApi = (invoiceingProfileId) => {
  return axiosInstance
    .get(`v1/invoicingProfiles/report/${invoiceingProfileId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleInvoicingProfileDataApi = (invoicingProfileId) => {
  return axiosInstance
    .get(`v1/invoicingProfiles/single/${invoicingProfileId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createInvoicingProfileAPi = (invoicingProfileDetail) => {
  return axiosInstance
    .post("v1/invoicingProfiles", invoicingProfileDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const editInvoicingProfileAPi = (
  invoicingProfileId,
  invoicingProfileDetail
) => {
  return axiosInstance
    .patch(
      `v1/invoicingProfiles/${invoicingProfileId}`,
      invoicingProfileDetail,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getInvoicingProfileBySupplier = (supplierId) => {
  return axiosInstance
    .get(`v1/invoicingProfiles/get/ip/by-supplier/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getInvoicingProfileByCC = (ccId) => {
  return axiosInstance
    .get(`v1/invoicingProfiles/get/ip/by-cc/${ccId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete legal entity by ID
export const deleteIPApi = (ipId) => {
  return axiosInstance
    .delete(`v1/invoicingProfiles/${ipId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllInvoicingFiltersApi = () => {
  return axiosInstance
    .get(`v1/invoicingProfiles/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
