import { axiosInstance } from "../utils/common";

export const getAllRolesApi = () => {
  return axiosInstance
    .get("v1/userRoles")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllRolesPaginationApi = (page, limit, filterObj) => {
  return axiosInstance
    .post(`v1/userRoles/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleRolesApi = (roleId) => {
  return axiosInstance
    .get(`v1/userRoles/${roleId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createRoleAPi = (roleDetails) => {
  return axiosInstance
    .post("v1/userRoles", roleDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editRoleAPi = (roleId, updatedRoleDetails) => {
  return axiosInstance
    .patch(`v1/userRoles/${roleId}`, updatedRoleDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteUserRoleAPi = (roleId) => {
  return axiosInstance
    .delete(`v1/userRoles/${roleId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllRoleFiltersApi = () => {
  return axiosInstance
    .get(`v1/userRoles/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
