import React, { useState, useEffect } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Row, Col, Table, Pagination, message } from "antd";
import {
  userDataAtom,
  RoleAccessAtom,
  HeaderChangerAtom,
} from "../../../store/store";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import { getAllInstanceFiltersApi } from "../../../api/actionInstanceApi";
import { getFiltersApi } from "../../../api/usersApi";
import { getAllActionInstanceApi } from "../../../api/actionInstanceApi";
import { createActivityLogApi } from "../../../api/logApi";
import { useRecoilState } from "recoil";
import moment from "moment";
import GlobalFilter from "../../GlobalFilter";

const AllActionInstanceView = ({ parentTabKey }) => {
  const roleUser = useRecoilState(RoleAccessAtom); //global state for getting logged-in user role access data
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //global state for changing header
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 10,
    total: 0,
  }); // instance table pagination
  const [loader, setLoader] = useState(false); //table loader
  const [tableData, setTableData] = useState([]); // state for store table data
  const history = useNavigate(); //react-router-dom navigate method
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold column data visibility status
  const [colFilterShow, setColFilterShow] = useState(false);
  // State variable to hold save filter visibility status
  const [savedFilterShow, setSavedFilterShow] = useState(false);
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();

  //Code below is for Global Filters

  // Function to handle get all supplier  table data
  useEffect(() => {
    getAllInstanceFiltersApi().then((res) => {
      console.log("filterRes: ", res);
      setFilterDynamic(res);
    });
  }, []);

  useEffect(() => {
    console.log("filterDynamic", filterDynamic);
  }, [filterDynamic]);

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res: ", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) => item.action_management_filter_data_view?.view_filter
            )
            .filter((n) => n);
        console.log("filterData: ", systemSetupFilterData);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      getAllActionInstance(1, 20, searchVal, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        getAllActionInstance(1, 20, searchVal, allFilterVal);
      } else {
        getAllActionInstance(1, 20, searchVal, allFilterVal);
      }
    }
    console.log("tester", allFilterVal);
  }, [allFilterVal]);

  const isColumnSorted = (column) => sortedColumn === column;
  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: renderColumnTitle("Instance Name", "action_instance_name"),
      dataIndex: "action_instance_name",
      align: "left",
      sorter: (a, b) =>
        a.action_instance_name.props.children.localeCompare(
          b.action_instance_name.props.children
        ),
      sortOrder: isColumnSorted("action_instance_name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Action Name", "action_name"),
      dataIndex: "action_name",
      align: "left",
      sorter: (a, b) => a.action_name.localeCompare(b.action_name),
      sortOrder: isColumnSorted("action_name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Action Status", "action_status"),
      dataIndex: "action_status",
      align: "left",
      sorter: (a, b) => a.action_status.localeCompare(b.action_status),
      sortOrder: isColumnSorted("action_status") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Action Level", "action_level"),
      dataIndex: "action_level",
      align: "left",
      sorter: (a, b) => a.action_level.localeCompare(b.action_level),
      sortOrder: isColumnSorted("action_level") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Owner", "owner"),
      dataIndex: "owner",
      align: "left",
      sorter: (a, b) => a.owner.localeCompare(b.owner),
      sortOrder: isColumnSorted("owner") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Approver", "approver"),
      dataIndex: "approver",
      align: "left",
      sorter: (a, b) => a.approver.localeCompare(b.approver),
      sortOrder: isColumnSorted("approver") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Due By", "due_by"),
      dataIndex: "due_by",
      align: "left",
      sorter: (a, b) => a.due_by.localeCompare(b.due_by),
      sortOrder: isColumnSorted("due_by") ? sortOrder : false,
    },
  ];

  const getAllActionInstance = (page, limit, searchVal, allFilterVal) => {
    console.log("allFilterVal", allFilterVal);
    setLoader(true);
    getAllActionInstanceApi(page, limit, searchVal, allFilterVal).then(
      (res) => {
        console.log("res", res);
        setLoader(false);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });

        const actionData = res.docs.map((item, index) => {
          let tableRow = {
            key: item.id ? item.id : item._id,
            action_instance_name: (
              <span
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.action_instance
                      .view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.action_instance
                      .view
                      ? "pointer"
                      : "default",
                }}
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.action_instance
                      .view
                  ) {
                    setHeaderChanger({
                      header: "actionManagement",
                      headerItem: "2",
                      headerItemName: "actionManagement",
                      stateSetter: item,
                    });
                    let actionLog = {
                      ss_id: item._id,
                      ss_name:
                        item && item.action_instance_name
                          ? item.action_instance_name
                          : "",
                      code: "action_instance",
                      author: userData[0].id,
                      action: "view",
                      notes: "Viewed Action Instance",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(actionLog);
                    history(`/action-management/view-action-instance`, {
                      state: item,
                    });
                  }
                }}
              >
                {item && item.action_instance_name
                  ? item.action_instance_name
                  : ""}
              </span>
            ),
            action_name:
              item.root_action && item.root_action.length !== 0
                ? item.root_action[0].action_name
                : "",
            action_status: item.instance_status,
            action_level:
              item.root_action && item.root_action.length !== 0
                ? item.root_action[0].action_level
                : "",
            owner: item.action_owner_name,
            approver: item.action_approver_name,
            due_by: moment(item.due_date).format("DD MMM YYYY"),
          };
          return tableRow;
        });
        setTableData(actionData);
      }
    );
  };

  // function for handle sorting in root action table
  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  useEffect(() => {
    getAllActionInstance(1, 10, allFilterVal);
  }, []);

  // action instance table pagination handler
  const onPageChange = (page, limit) => {
    getAllActionInstance(page, limit, allFilterVal);
  };

  useEffect(() => {
    setSavedFilterShow(false);
    setColFilterShow(false);
  }, [parentTabKey]);

  return (
    <Row>
      {saveMessageContextHolder}
      <Col span={24}>
        <div className="filter-white-space-head">
          <GlobalFilter
            selectedTab={"instance"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </div>
        <Row>
          <Col span={24}>
            <Table
              onChange={handleTableChange}
              dataSource={tableData}
              rowClassName={() => "contract-tree-table-row"}
              columns={columns}
              loading={loader}
              showSorterTooltip={false}
              bordered
              size="large "
              scroll={{ x: "calc(300px + 50%)", y: 400 }}
              pagination={false}
              className="system-setup-table mt-10"
            />

            {/* table pagination */}
            <div className="float-right mb-15 mt-15">
              <Pagination
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AllActionInstanceView;
