import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image, Row, Col, Table, Layout, Pagination, message } from "antd"; //antd components
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import TopHeader from "../../Header";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import { useRecoilState } from "recoil";
import edit from "../../../assets/images/icons/edit_pen.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import {
  clauseLibraryPaginationApi,
  deleteclauseLibrary,
  getAllClauseLibraryFiltersApi,
} from "../../../api/contractAuthorityApi";
import { getFiltersApi } from "../../../api/usersApi";
import moment from "moment";
import { CreateButton } from "../../GlobalButton";
import { createActivityLogApi } from "../../../api/logApi";
import { userDataAtom } from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import GlobalFilter from "../../GlobalFilter";

const ClauseLibrary = () => {
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom); //global state for getting logged-in user role access data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const navigate = useNavigate();
  const menuKey = "6";
  const [loader, setLoader] = useState(false);
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 10,
    total: 0,
  });
  const [searchVal, setSearchVal] = useState("null");
  const [tableData, setTableData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(null);

  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();

  // Variable to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  // Function to handle get all supplier  table data
  useEffect(() => {
    getAllClauseLibraryFiltersApi().then((res) => {
      console.log("filterRes: ", res);
      setFilterDynamic(res);
    });
  }, []);

  useEffect(() => {
    console.log("filterDynamic", filterDynamic);
  }, [filterDynamic]);

  const handleTableChange = (_, __, sorter) => {
    /*This if-elseIf combo makes sure 'Updated On' column is always sorted
      in descending order by default and sorting cannot be disabled for this column*/
    if (!sorter.column && sorter.field === "last_updated_on") {
      setSortedColumn("last_updated_on");
      setSortOrder("ascend");
      return;
    } else if (!sorter.column && sorter.field !== "last_updated_on") {
      setSortedColumn("last_updated_on");
      setSortOrder("descend");
      return;
    }

    //This if-else combo applies to other columns and can disable sorting
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res: ", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) =>
                item.contract_authority_filter_data?.clause_library_filter
            )
            .filter((n) => n);
        console.log("filterData: ", systemSetupFilterData);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      renderClauseLibrary(1, 20, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        renderClauseLibrary(1, 20, allFilterVal);
      } else {
        renderClauseLibrary(1, 20, allFilterVal);
      }
    }
  }, [allFilterVal]);

  //Api integration to delete a clause library
  /* Function to handle delete the clause library
   *  @param {string} id - The unique identifier for each clause library in the database
   *  @param {string} name - The full name of the clause library header that is going to be deleted
   *  @param {string} reason - The input name must been matched clause library header name
   */
  const handleDeleteCL = (clId, headerName, inputHeaderName) => {
    if (inputHeaderName === headerName) {
      deleteclauseLibrary(clId).then((res) => {
        let umLog = {
          ss_id: clId,
          ss_name: headerName,
          code: "users",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted Clause Library",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(headerName, ModalMessage.CL_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              renderClauseLibrary(1, 10, allFilterVal);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.CL_Delete_Error);
    }
  };

  const renderClauseLibrary = (page, limit, allFilterVal) => {
    setLoader(true);
    console.log("allFilterVal", allFilterVal);
    clauseLibraryPaginationApi("null", page, limit, allFilterVal).then(
      (res) => {
        console.log("res--", res);
        setLoader(false);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        let clauseLibrary = res.docs.map((data) => {
          let tableRow = {
            key: data.id ? data.id : data._id,
            header_name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .view
                  ) {
                    setHeaderChanger({
                      header: "contractAuthority",
                      headerItem: "1",
                      headerItemName: "contractAuthority",
                      stateSetter: data,
                    });
                    navigate(`/contract_authority/view/clause-library`, {
                      state: data,
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.header_name}
              </span>
            ),
            header_description: data.header_desc,
            created_by: data.created_user_name,
            last_updated_on: moment(data.updated_on).format("DD-MMM-YYYY"),
            status: data.status,
            action: (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  {roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .view && (
                      <EyeOutlined
                        title="View"
                        style={{
                          fontSize: "20px",
                          color: "var(--color-solid-darkgrey)",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          let ssLog = {
                            ss_id: data.id ? data.id : data._id,
                            ss_name: data.header_name,
                            code: "clause_library",
                            author: userData[0].id,
                            action: "view",
                            notes: "viewed Clause Library",
                            updated_on: new Date(),
                          };
                          createActivityLogApi(ssLog);
                          setHeaderChanger({
                            header: "contractAuthority",
                            headerItem: "1",
                            headerItemName: "contractAuthority",
                            stateSetter: data,
                          });
                          navigate(`/contract_authority/view/clause-library`, {
                            state: data,
                          });
                        }}
                      />
                    )}
                  {roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .create && (
                      <Image
                        title="Edit"
                        src={edit}
                        preview={false}
                        style={{ width: "20px" }}
                        className="cursor-pointer"
                        onClick={() => {
                          setHeaderChanger({
                            header: "contractAuthority",
                            headerItem: "1",
                            headerItemName: "contractAuthority",
                            stateSetter: data,
                          });
                          navigate(`/contract_authority/edit/clause-library`, {
                            state: data,
                          });
                        }}
                      />
                    )}
                  {roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.contract_authority.clause_library
                      .create && (
                      <Image
                        title="Delete"
                        src={deleteIcon}
                        preview={false}
                        style={{ width: "20px" }}
                        className="cursor-pointer"
                        onClick={() => {
                          popupModalInstance
                            .confirModal(ModalMessage.CL_Delete_Info)
                            .then(async (userchoice) => {
                              if (userchoice.action === "delete") {
                                handleDeleteCL(
                                  data.id ? data.id : data._id,
                                  data.header_name,
                                  userchoice.inputValue
                                );
                              }
                            });
                        }}
                      />
                    )}
                </div>
              </>
            ),
          };
          return tableRow;
        });
        console.log("clauselibraryrender..", clauseLibrary);
        setTableData(clauseLibrary);
      }
    );
  };

  useEffect(() => {
    renderClauseLibrary(1, 10, allFilterVal);
  }, [searchVal]);

  const onPageChange = (page, limit) => {
    renderClauseLibrary(page, limit, allFilterVal);
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    handleTableChange("", "", {
      column: { dataIndex: "last_updated_on" },
      order: "descend",
    });
  }, []);

  const col = [
    {
      title: renderColumnTitle("Header Name", "header_name"),
      dataIndex: "header_name",
      key: "header_name",
      align: "left",
      sorter: (a, b) =>
        a.header_name.props.children.localeCompare(
          b.header_name.props.children
        ),
      sortOrder: isColumnSorted("header_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Created By", "created_by"),
      dataIndex: "created_by",
      key: "created_by",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.created_by?.toLowerCase();
        const valueB = b.created_by?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("created_by") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Last Updated On", "last_updated_on"),
      dataIndex: "last_updated_on",
      key: "last_updated_on",
      align: "left",
      sorter: (a, b) =>
        moment(a.last_updated_on).unix() - moment(b.last_updated_on).unix(),
      sortOrder: isColumnSorted("last_updated_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      key: "status",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.status;
        const valueB = b.status;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.contract_authority.clause_library.view ||
          roleUser[0].page_access.contract_authority.clause_library.create)
          ? true
          : false,
    },
  ];

  const colToShow = col.filter((col) => col.show !== false);

  useEffect(() => {
    document.title = "Clause Library | Judo";
  }, []);

  return (
    <Layout className="header-layout">
      {saveMessageContextHolder}
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <div className="contract-pipeline">
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.clause_library.create
                ? 15
                : 24
            }
            lg={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.clause_library.create
                ? 15
                : 24
            }
            xl={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.clause_library.create
                ? 22
                : 24
            }
          >
            <GlobalFilter
              selectedTab={"clauseLibrary"}
              initialTableData={tableData}
              allFilterVal={allFilterVal}
              setAllFilterVal={setAllFilterVal}
            />
          </Col>

          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.contract_authority.clause_library
              .create && (
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <Link
                  id="CreateClauseLibrary"
                  to="/contract_authority/create/clause-library"
                >
                  <CreateButton />
                </Link>
              </Col>
            )}
        </Row>

        <Row>
          <Col span={24}>
            <Table
              // rowClassName={() => "contract-tree-table-row"}
              dataSource={tableData}
              columns={colToShow}
              onChange={handleTableChange}
              showSorterTooltip={false}
              bordered
              size="large "
              scroll={{ x: "calc(300px + 50%)", y: 400 }}
              pagination={false}
              loading={loader}
              className="system-setup-table mt-10"
            />
            <div className="float-right mb-15 mt-15">
              <Pagination
                // simple
                // pageSizeOptions={[5, 10, 20, 50, 100]}
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ClauseLibrary;
