import { axiosInstance } from "../utils/common";

export const getAllWorkflowsApi = () => {
  return axiosInstance
    .get("v1/workflow")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const workflowPaginationApi = (page, limit, searchVal, filterObj) => {
  return axiosInstance
    .post(`v1/workflow/pagination/${searchVal}?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllWorkflowsOnlyApi = () => {
  return axiosInstance
    .get("v1/workflow/only")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleWorkflowsApi = (workflowId) => {
  return axiosInstance
    .get(`v1/workflow/${workflowId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const getSingleWorkflowsReportApi = (workflowId) => {
  return axiosInstance
    .get(`v1/workflow/report/${workflowId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createWorkflowAPi = (workflowDetails) => {
  return axiosInstance
    .post("v1/workflow", workflowDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// export const createCustomWorkflowAPi = (workflowDetails) => {
//   console.log("workflowDetails***", workflowDetails);
//   return axiosInstance
//     .post("v1/workflow/custom", workflowDetails, {
//       headers: { "Content-Type": "multipart/form-data" },
//     })
//     .then((res) => res.data);
// };

export const createCustomWorkflowAPi = (workflowDetails) => {
  console.log("workflowDetails***", workflowDetails);
  return axiosInstance
    .post(
      "v1/workflow/custom",
      workflowDetails
      //   {
      //   headers: { "Content-Type": "multipart/form-data" },
      // })
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editWorkflowAPi = (workflowId, updatedWorkflowDetails) => {
  return axiosInstance
    .patch(`v1/workflow/${workflowId}`, updatedWorkflowDetails)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const onApproveApi = (approveDetails) => {
  return axiosInstance
    .post("v1/workflow/approve", approveDetails, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const onRejectApi = (rejectDetails) => {
  return axiosInstance
    .post("v1/workflow/reject", rejectDetails, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const onReturnApi = (returnDetails) => {
  return axiosInstance
    .post("v1/workflow/return", returnDetails, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const checkApprovalApi = (contractId, level) => {
  return axiosInstance
    .post(`v1/workflow/check/approval/${contractId}/${level}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getLevelApi = (contractId, email) => {
  return axiosInstance
    .post(`v1/workflow/get/level/${contractId}/${email}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const deleteWFApi = (workflowId) => {
  return axiosInstance
    .delete(`v1/workflow/${workflowId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getWorkflowByUser = (user) => {
  return axiosInstance
    .get(`v1/workflow/get/by-user/${user}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllWorkflowFiltersApi = () => {
  return axiosInstance
    .get(`v1/workflow/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
