import React, { useEffect, useMemo, useState } from "react";
import "../assets/style/databaseSeeder.css";
import {
  Row,
  Col,
  Layout,
  InputNumber,
  Divider,
  Progress,
  Button,
  Tree,
  Switch,
} from "antd";
import {
  ExperimentTwoTone,
  LoadingOutlined,
  RiseOutlined,
  FallOutlined,
  BarChartOutlined,
  InfoCircleFilled,
  TagsFilled,
} from "@ant-design/icons";
import {
  countSeededContracts,
  newFetchAcvTcv,
  fetchContractTree,
  seedDatabaseApi,
  oldFetchAcvTcv,
  oldPipelineApi,
} from "../api/testJudoApi";
import { AddButton, DeleteButton } from "../components/GlobalButton";
import TopHeader from "../components/Header";
import { HeaderChangerAtom, userDataAtom } from "../store/store";
import { useRecoilState } from "recoil";
import { notificationSocket } from "../utils/notificationSocket";
import { treeDataApi } from "../api/suppliersApi";
import { contractPipelineApi } from "../api/contractsApi";

const DatabaseSeeder = () => {
  const userData = useRecoilState(userDataAtom);
  const [headerChanger, _] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;
  const [addLoader, setAddLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [seededContractCount, setSeededContractCount] = useState(0);
  const [insertSupplierInput, setInsertSupplierInput] = useState(0);
  const [insertChildrenInput, setInsertChildrenInput] = useState(0);
  const [insertLevelsInput, setInsertLevelsInput] = useState(0);
  const [insertCount, setInsertCount] = useState(0);
  const [progressInfo, setProgressInfo] = useState("none");
  const [fetchedContracts, setFetchedContracts] = useState([]);
  const [expandLoader, setExpandLoader] = useState(false);
  const [testContract, setTestContract] = useState({});
  const [oldTree, setOldTree] = useState([]);
  const [lazyLoadPerf, setLazyLoadPerf] = useState({});
  const [treeLazyLoader, setTreeLazyLoader] = useState(false);
  const [acvTcvPerf, setAcvTcvPerf] = useState({});
  const [acvTcvLoader, setAcvTcvLoader] = useState(false);
  const [pipelinePerf, setPipelinePerf] = useState({});
  const [pipelineLoader, setPipelineLoader] = useState(false);
  const [pipelineSwitch, setPipelineSwitch] = useState("all");

  const contractTree = async (supplierId, contractId, parentType) => {
    try {
      setExpandLoader(true);
      const contractTreeRes = await fetchContractTree(
        supplierId,
        contractId,
        parentType
      );

      console.log("newTreeApi: ", contractTreeRes);

      const addChildrenToTree = (prevTree, contractId, newChildren) => {
        return prevTree.map((contract) => {
          if (contract._id === contractId) {
            // Add new children to the expanded contract
            return {
              ...contract,
              children: newChildren,
            };
          } else if (
            contract.children &&
            contract.children.length &&
            typeof contract.children !== "boolean"
          ) {
            // Recursively search within the children
            return {
              ...contract,
              children: addChildrenToTree(
                contract.children,
                contractId,
                newChildren
              ),
            };
          }
          return contract;
        });
      };

      setFetchedContracts((prevTree) => {
        if (!contractId) {
          return contractTreeRes;
        }
        // Find the contract and update with new children
        const updatedTree = addChildrenToTree(
          prevTree,
          contractId,
          contractTreeRes[0].children
        );
        console.log("prevTree: ", prevTree);
        console.log("updatedTree: ", updatedTree);
        return updatedTree;
      });
      setExpandLoader(false);
    } catch (error) {
      console.error("Error fetching contract tree:", error);
    }
  };

  useEffect(() => {
    countSeededContracts().then((res) => setSeededContractCount(res));
    contractTree(undefined, undefined, "Supplier");
  }, []);

  notificationSocket.on("insertContract", (contractInsertCount) => {
    setInsertCount(contractInsertCount);
    setProgressInfo("insertContract");
  });

  notificationSocket.on("insertComplete", () => {
    setInsertCount(totalContracts);
    setProgressInfo("insertComplete");
  });

  notificationSocket.on("deleteSeedContract", () => {
    setInsertCount(3);
    setProgressInfo("deleteSeedContract");
  });

  notificationSocket.on("deleteSeedMetadata", () => {
    setInsertCount(2);
    setProgressInfo("deleteSeedMetadata");
  });

  notificationSocket.on("deleteSeedWorkflow", () => {
    setInsertCount(1);
    setProgressInfo("deleteSeedWorkflow");
  });

  notificationSocket.on("deleteComplete", () => {
    setInsertCount(0);
    setProgressInfo("deleteComplete");
  });

  const totalContracts = useMemo(() => {
    function calculateContracts(suppliers, children, levels) {
      if (children === 1) {
        return suppliers * levels;
      }

      return (
        (suppliers * children * (Math.pow(children, levels) - 1)) /
        (children - 1)
      );
    }

    return calculateContracts(
      insertSupplierInput,
      insertChildrenInput,
      insertLevelsInput
    );
  }, [insertSupplierInput, insertChildrenInput, insertLevelsInput]);

  const onSelect = (selectedKeys, info) => {
    console.log("selected", info);
    if (info.node.type !== "Supplier") {
      setTestContract({
        id: info.node._id,
        supplier_id: info.node.supplier_id,
        contract_name: info.node.title,
      });
    }
  };

  const onLoad = (selectedKeys, info) => {
    console.log("loaded", info);
  };

  const onExpand = async (selectedKeys, info) => {
    console.log("expanded", info);
    const newTree = await contractTree(
      info.node.supplier_name ? info.node._id : undefined,
      info.node._id,
      info.node.type
    );

    console.log("newTree: ", newTree);
  };

  // useEffect(() => {
  //   console.log("pipelinePerf: ", pipelinePerf);
  // }, [pipelinePerf]);

  // useEffect(() => {
  //   console.log("testContract: ", testContract);
  //   console.log("acvTcvPerf: ", acvTcvPerf);
  // }, [testContract, acvTcvPerf]);

  useEffect(() => {
    document.title = "Test Playground | Judo";
  }, []);

  const onPipelineSwitchChange = (checked) => {
    setPipelineSwitch(checked ? userData[0].id : "all");
  };

  return (
    <Layout
      style={{
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <TopHeader
        headerChange={header}
        menuKey={"5"}
        logo={true}
        feedRow={false}
        searchRow={false}
      />
      <div className="seed-layout-wrapper">
        <Row className="mt-30 mb-20">
          <Col span={24}>
            <span className="seed-title">
              <ExperimentTwoTone style={{ marginRight: 10, fontSize: 20 }} />
              Judo Test Playground
            </span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[12, 12]}>
          {/* Bulk Contract Insertion */}
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row align="middle" className="mb-20">
              <Col span={23}>
                <span className="seed-title">
                  {`\u00A0 \u2022 \u00A0`} Seed Contracts
                </span>
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">Current Seed Count</span>
              </Col>
              <Col span={16}>
                <InputNumber
                  disabled
                  className="seed-input-number"
                  value={seededContractCount}
                  readOnly
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">Number of Suppliers</span>
              </Col>
              <Col span={16}>
                <InputNumber
                  className="seed-input-number"
                  value={insertSupplierInput}
                  min={0}
                  onChange={(number) => {
                    setInsertCount(0);
                    if (
                      progressInfo === "insertComplete" ||
                      progressInfo !== ""
                    ) {
                      setProgressInfo("none");
                    }
                    setInsertSupplierInput(number === null ? 0 : number);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">Number of Children</span>
              </Col>
              <Col span={16}>
                <InputNumber
                  className="seed-input-number"
                  value={insertChildrenInput}
                  min={0}
                  onChange={(number) => {
                    setInsertCount(0);
                    if (
                      progressInfo === "insertComplete" ||
                      progressInfo !== ""
                    ) {
                      setProgressInfo("none");
                    }
                    setInsertChildrenInput(number === null ? 0 : number);
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">Number of Levels</span>
              </Col>
              <Col span={4}>
                <InputNumber
                  className="seed-input-number"
                  value={insertLevelsInput}
                  min={0}
                  onChange={(number) => {
                    setInsertCount(0);
                    if (
                      progressInfo === "insertComplete" ||
                      progressInfo !== ""
                    ) {
                      setProgressInfo("none");
                    }
                    setInsertLevelsInput(number === null ? 0 : number);
                  }}
                />
              </Col>
              <Col span={11}>
                {totalContracts !== 0 ? (
                  <span className="seed-field">
                    {`${totalContracts.toLocaleString(
                      "en-us"
                    )} contracts will be created`}
                  </span>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">
                  {progressInfo === "none"
                    ? "Seed Progress"
                    : progressInfo === "insertContract"
                    ? "Inserting Contracts..."
                    : progressInfo === "insertMetadata"
                    ? "Inserting Contract Metadata..."
                    : progressInfo === "insertWorkflow"
                    ? "Inserting Contract Workflow..."
                    : progressInfo === "insertCommercial"
                    ? "Inserting Contract Commercial..."
                    : progressInfo === "insertComplete"
                    ? "Seeding Complete"
                    : progressInfo === "deleteSeedContract"
                    ? "Removing Contracts..."
                    : progressInfo === "deleteSeedMetadata"
                    ? "Removing Contract Metadatas..."
                    : progressInfo === "deleteSeedWorkflow"
                    ? "Removing Contract Workflows..."
                    : progressInfo === "deleteComplete"
                    ? "DeSeeding Complete"
                    : "Seed Progress"}
                </span>
              </Col>
              <Col span={12}>
                <Progress
                  status={`${
                    progressInfo === "deleteComplete" ||
                    progressInfo === "insertComplete"
                      ? "success"
                      : "active"
                  }`}
                  format={(percent) => (
                    <span className="nice-text">
                      {insertCount === 0 || totalContracts === 0
                        ? ``
                        : `${insertCount} / ${parseFloat(totalContracts)} ${
                            percent !== 0
                              ? `\u00A0 \u2022 \u00A0 ${Math.round(percent)}%`
                              : ``
                          }`}
                    </span>
                  )}
                  percent={
                    (insertCount /
                      (progressInfo.startsWith("delete")
                        ? 4
                        : totalContracts)) *
                    100
                  }
                  type="line"
                />
              </Col>
            </Row>
            <Row
              gutter={[12, 0]}
              justify="start"
              align="middle"
              className="mb-20"
            >
              <Col span={4}>
                <AddButton
                  loading={addLoader}
                  disabled={
                    insertSupplierInput === 0 ||
                    insertChildrenInput === 0 ||
                    insertLevelsInput === 0
                  }
                  onClick={async () => {
                    try {
                      setAddLoader(true);
                      const seed = await seedDatabaseApi(
                        insertSupplierInput,
                        insertChildrenInput,
                        insertLevelsInput,
                        userData[0].id,
                        userData[0].email,
                        "insert"
                      ).then(() => {
                        // setProgressInfo("none");
                        contractTree(undefined, undefined, "Supplier");
                        setSeededContractCount((prev) => prev + totalContracts);
                        setAddLoader(false);
                      });
                      console.log("clicked: ", seed);
                    } catch (error) {
                      setAddLoader(false);
                      console.error("Error seeding database: ", error);
                    }
                  }}
                />
              </Col>
              <Col span={7}>
                <DeleteButton
                  loading={removeLoader}
                  onClick={async () => {
                    try {
                      setRemoveLoader(true);
                      const seed = await seedDatabaseApi(
                        insertSupplierInput,
                        insertChildrenInput,
                        insertLevelsInput,
                        userData[0].id,
                        userData[0].email,
                        "remove"
                      ).then(() => {
                        contractTree(undefined, undefined, "Supplier");
                        setProgressInfo("deleteComplete");
                        setSeededContractCount(0);
                        setRemoveLoader(false);
                      });

                      console.log("clicked: ", seed);
                    } catch (error) {
                      setRemoveLoader(false);
                      console.error("Error seeding database: ", error);
                    }
                    setInsertCount(0);
                    // setProgressInfo("none");
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col span={23}>
                <div className="seed-info-wrapper">
                  <InfoCircleFilled style={{ fontSize: 15, color: "red" }} />
                  <p className="seed-info-text">
                    Once the insertion of contracts begins in the database,
                    there is no mechanism to stop the transaction for now
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <div className="seed-info-wrapper">
                  <InfoCircleFilled
                    style={{
                      fontSize: 15,
                      color: "var(--color-solid-darkerblue)",
                    }}
                  />
                  <p className="seed-info-text">
                    The Delete button will remove any seeded contracts added in
                    this session or in any prior sessions
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          {/* Test Contract Tree Lazy Loading */}
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row align="middle" className="mb-20">
              <Col span={24}>
                <span className="seed-title">
                  {`\u00A0 \u2022 \u00A0`} Test Contract Tree Lazy Loading
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Divider type="vertical" />
                    <TagsFilled
                      style={{ color: "var(--color-solid-darkyellow)" }}
                    />
                    {`\u00A0`}
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "var(--color-solid-darkergrey)",
                      }}
                    >
                      Pending Implementation
                    </span>
                  </div>
                </span>
              </Col>
            </Row>
            <Row gutter={[12, 0]} className="mt-20 mb-20">
              {lazyLoadPerf.unoptimized && oldTree.length !== 0 ? (
                <Col span={12}>
                  <Tree
                    treeData={oldTree}
                    showLine
                    showIcon
                    height={160}
                    style={{
                      padding: "15px 20px 10px 20px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: 10,
                      minHeight: 185,
                    }}
                  />
                </Col>
              ) : (
                <Col span={12}>
                  <div
                    style={{
                      height: 185,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </Col>
              )}
              {lazyLoadPerf.optimized && fetchedContracts.length !== 0 ? (
                <Col span={12}>
                  <Tree
                    treeData={fetchedContracts}
                    showLine
                    showIcon
                    height={160}
                    style={{
                      padding: "15px 20px 10px 20px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: 10,
                      minHeight: 185,
                    }}
                    onSelect={onSelect}
                    onExpand={onExpand}
                    onLoad={onLoad}
                    titleRender={(node) => (
                      <>
                        {expandLoader && node._id === testContract._id && (
                          <LoadingOutlined />
                        )}
                        {expandLoader &&
                          node._id === testContract._id &&
                          `\u00A0 \u2022 \u00A0`}
                        {node.title}
                      </>
                    )}
                  />
                </Col>
              ) : (
                <Col span={12}>
                  <div
                    style={{
                      height: 185,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </Col>
              )}
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Before Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">
                      {`${parseFloat(
                        lazyLoadPerf.unoptimized
                          ? lazyLoadPerf.unoptimized / 1000
                          : 0
                      ).toFixed(2)}
                      s`}
                    </span>
                  )}
                  percent={lazyLoadPerf.unoptimized ? 100 : 0}
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`After Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">{`${parseFloat(
                      lazyLoadPerf.optimized ? lazyLoadPerf.optimized / 1000 : 0
                    ).toFixed(2)} s`}</span>
                  )}
                  percent={
                    (lazyLoadPerf.optimized / lazyLoadPerf.unoptimized
                      ? lazyLoadPerf.optimized / lazyLoadPerf.unoptimized
                      : 0) * 100
                  }
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Performance Difference`}</span>
              </Col>
              <Col span={12}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color:
                      lazyLoadPerf.unoptimized < lazyLoadPerf.optimized
                        ? "var(--color-solid-red)"
                        : "var(--color-solid-green)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {lazyLoadPerf.unoptimized && lazyLoadPerf.optimized
                    ? `${
                        lazyLoadPerf.unoptimized > lazyLoadPerf.optimized
                          ? (
                              ((lazyLoadPerf.unoptimized -
                                lazyLoadPerf.optimized) /
                                lazyLoadPerf.unoptimized) *
                              100
                            ).toFixed(2)
                          : (
                              ((lazyLoadPerf.optimized -
                                lazyLoadPerf.unoptimized) /
                                lazyLoadPerf.optimized) *
                              100
                            ).toFixed(2)
                      }%`
                    : `0%`}
                  {lazyLoadPerf.unoptimized > lazyLoadPerf.optimized ? (
                    <RiseOutlined
                      style={{
                        marginLeft: 5,
                        fontSize: 15,
                        fontWeight: 600,
                        color: "var(--color-solid-green)",
                      }}
                    />
                  ) : (
                    lazyLoadPerf.unoptimized < lazyLoadPerf.optimized && (
                      <FallOutlined
                        style={{
                          marginLeft: 5,
                          fontSize: 15,
                          fontWeight: 600,
                          color: "var(--color-solid-red)",
                        }}
                      />
                    )
                  )}
                </span>
              </Col>
            </Row>
            <Row justify="start" align="middle">
              <Col span={7}>
                <Button
                  loading={treeLazyLoader}
                  icon={<BarChartOutlined style={{ fontSize: 16 }} />}
                  type="primary"
                  className="perf-button"
                  onClick={async () => {
                    setTreeLazyLoader(true);
                    setLazyLoadPerf({});
                    setFetchedContracts([]);
                    setOldTree([]);
                    let startTime = "";
                    let endTime = "";

                    startTime = Date.now();
                    await contractTree(undefined, undefined, "Supplier");
                    endTime = Date.now();

                    setLazyLoadPerf({ optimized: endTime - startTime });

                    startTime = Date.now();
                    await treeDataApi(userData[0].id, "ContractTree").then(
                      (res) => {
                        console.log("supplierTree: ", res);
                        setOldTree(res);
                      }
                    );
                    endTime = Date.now();

                    setLazyLoadPerf((prev) => ({
                      ...prev,
                      unoptimized: endTime - startTime,
                    }));

                    setTreeLazyLoader(false);
                  }}
                >
                  Check Performance
                </Button>
              </Col>
            </Row>
          </Col>
          <Divider />
          {/* Test ACV Calculation Performance */}
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row align="middle" className="mb-20">
              <Col span={23}>
                <span className="seed-title">
                  {`\u00A0 \u2022 \u00A0`} Test ACV Calculation Performance
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Divider type="vertical" />
                    <TagsFilled style={{ color: "var(--color-solid-green)" }} />
                    {`\u00A0`}
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "var(--color-solid-darkergrey)",
                      }}
                    >
                      Successfully Implemented
                    </span>
                  </div>
                </span>
              </Col>
            </Row>
            <Row gutter={[12, 0]} className="mt-20 mb-20">
              <Col span={23}>
                <Row className="mb-5">
                  <Col span={24}>
                    <div className="seed-info-wrapper">
                      <InfoCircleFilled
                        style={{
                          fontSize: 15,
                          color: "var(--color-solid-darkerblue)",
                        }}
                      />
                      <p className="seed-info-text">
                        Select contract to test the speed of Aggregate Contract
                        Value calculation
                      </p>
                    </div>
                  </Col>
                </Row>
                <Tree
                  treeData={fetchedContracts}
                  showLine
                  showIcon
                  height={225}
                  style={{
                    padding: "15px 20px 10px 20px",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: 10,
                    minHeight: 250,
                  }}
                  onSelect={onSelect}
                  onExpand={onExpand}
                  onLoad={onLoad}
                  titleRender={(node) => (
                    <>
                      {expandLoader && node._id === testContract._id && (
                        <LoadingOutlined />
                      )}
                      {expandLoader &&
                        node._id === testContract._id &&
                        `\u00A0 \u2022 \u00A0`}
                      {node.title}
                    </>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Before Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">
                      {`${parseFloat(
                        acvTcvPerf.unoptimized
                          ? acvTcvPerf.unoptimized / 1000
                          : 0
                      ).toFixed(2)} s`}
                    </span>
                  )}
                  percent={acvTcvPerf.unoptimized ? 100 : 0}
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`After Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">{`${parseFloat(
                      acvTcvPerf.optimized ? acvTcvPerf.optimized / 1000 : 0
                    ).toFixed(2)} s`}</span>
                  )}
                  percent={
                    (acvTcvPerf.optimized / acvTcvPerf.unoptimized
                      ? acvTcvPerf.optimized / acvTcvPerf.unoptimized
                      : 0) * 100
                  }
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Performance Difference`}</span>
              </Col>
              <Col span={12}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color:
                      acvTcvPerf.unoptimized < acvTcvPerf.optimized
                        ? "var(--color-solid-red)"
                        : "var(--color-solid-green)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {acvTcvPerf.unoptimized && acvTcvPerf.optimized
                    ? `${
                        acvTcvPerf.unoptimized > acvTcvPerf.optimized
                          ? (
                              ((acvTcvPerf.unoptimized - acvTcvPerf.optimized) /
                                acvTcvPerf.unoptimized) *
                              100
                            ).toFixed(2)
                          : (
                              ((acvTcvPerf.optimized - acvTcvPerf.unoptimized) /
                                acvTcvPerf.optimized) *
                              100
                            ).toFixed(2)
                      }%`
                    : `0%`}
                  {acvTcvPerf.unoptimized > acvTcvPerf.optimized ? (
                    <RiseOutlined
                      style={{
                        marginLeft: 5,
                        fontSize: 15,
                        fontWeight: 600,
                        color: "var(--color-solid-green)",
                      }}
                    />
                  ) : (
                    acvTcvPerf.unoptimized < acvTcvPerf.optimized && (
                      <FallOutlined
                        style={{
                          marginLeft: 5,
                          fontSize: 15,
                          fontWeight: 600,
                          color: "var(--color-solid-red)",
                        }}
                      />
                    )
                  )}
                </span>
              </Col>
            </Row>
            <Row justify="start" align="middle">
              <Col span={7}>
                <Button
                  loading={acvTcvLoader}
                  disabled={
                    Object.keys(testContract).length === 0 ||
                    (testContract.id && testContract.id.includes("Sup"))
                  }
                  icon={<BarChartOutlined style={{ fontSize: 16 }} />}
                  type="primary"
                  className="perf-button"
                  onClick={async () => {
                    setAcvTcvLoader(true);
                    let startTime = "";
                    let endTime = "";

                    startTime = Date.now();
                    const newAcvTcv = await newFetchAcvTcv(testContract.id);
                    console.log("newAcvTcv: ", newAcvTcv);

                    endTime = Date.now();

                    setAcvTcvPerf({ optimized: endTime - startTime });

                    startTime = Date.now();
                    const acvTcv = await oldFetchAcvTcv(testContract.id);
                    console.log("oldAcvTcv: ", acvTcv);

                    endTime = Date.now();

                    setAcvTcvPerf((prev) => ({
                      ...prev,
                      unoptimized: endTime - startTime,
                    }));

                    setAcvTcvLoader(false);
                  }}
                >
                  Check Performance
                </Button>
              </Col>
            </Row>
          </Col>
          {/* Test Contract Pipline Performance */}
          <Col xl={12} lg={24} md={24} sm={24} xs={24}>
            <Row align="middle" className="mb-20">
              <Col span={23}>
                <span className="seed-title">
                  {`\u00A0 \u2022 \u00A0`} Test Contract Pipline Performance
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Divider type="vertical" />
                    <TagsFilled style={{ color: "var(--color-solid-green)" }} />
                    {`\u00A0`}
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "var(--color-solid-darkergrey)",
                      }}
                    >
                      Successfully Implemented
                    </span>
                  </div>
                </span>
              </Col>
            </Row>
            <Row align="middle" className="mb-15">
              <Col span={24}>
                <Row align="middle" className="mt-5">
                  <Col span={1}>
                    <span className="nice-text">All</span>
                  </Col>
                  <Col span={2}>
                    <Switch onChange={onPipelineSwitchChange} />
                  </Col>
                  <Col span={8}>
                    <span className="nice-text">With Me</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={12}>
                {pipelinePerf.unOptimizedPipeline ? (
                  <div
                    style={{
                      height: 250,
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 10,
                      overflowY: "auto",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {pipelinePerf.unOptimizedPipeline.docs.map((contract) => (
                      <>
                        <span
                          style={{
                            display: "block",
                            marginBottom: 5,
                            color: "var(--color-solid-darkergrey)",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: "5px 10px",
                            border: "1px solid var(--color-border)",
                            borderRadius: 5,
                          }}
                        >
                          {contract.contract_name}
                        </span>
                      </>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      height: 250,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
              </Col>
              <Col span={12}>
                {pipelinePerf.optimizedPipeline ? (
                  <div
                    style={{
                      height: 250,
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 10,
                      overflowY: "auto",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {pipelinePerf.optimizedPipeline.docs.map((contract) => (
                      <>
                        <span
                          style={{
                            display: "block",
                            marginBottom: 5,
                            color: "var(--color-solid-darkergrey)",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: "5px 10px",
                            border: "1px solid var(--color-border)",
                            borderRadius: 5,
                          }}
                        >
                          {contract.contract_name}
                        </span>
                      </>
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      height: 250,
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Before Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">{`${parseFloat(
                      pipelinePerf.unoptimized
                        ? pipelinePerf.unoptimized / 1000
                        : 0
                    ).toFixed(2)} s`}</span>
                  )}
                  percent={pipelinePerf.unoptimized ? 100 : 0}
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`After Optimization`}</span>
              </Col>
              <Col span={12}>
                <Progress
                  format={() => (
                    <span className="nice-text">{`${parseFloat(
                      pipelinePerf.optimized ? pipelinePerf.optimized / 1000 : 0
                    ).toFixed(2)} s`}</span>
                  )}
                  percent={
                    (pipelinePerf.optimized / pipelinePerf.unoptimized
                      ? pipelinePerf.optimized / pipelinePerf.unoptimized
                      : 0) * 100
                  }
                  type="line"
                  status="active"
                />
              </Col>
            </Row>
            <Row gutter={[12, 0]} align="middle" className="mb-20">
              <Col span={8}>
                <span className="seed-field">{`Performance Difference`}</span>
              </Col>
              <Col span={12}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color:
                      pipelinePerf.unoptimized < pipelinePerf.optimized
                        ? "var(--color-solid-red)"
                        : "var(--color-solid-green)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {pipelinePerf.unoptimized && pipelinePerf.optimized
                    ? `${
                        pipelinePerf.unoptimized > pipelinePerf.optimized
                          ? (
                              ((pipelinePerf.unoptimized -
                                pipelinePerf.optimized) /
                                pipelinePerf.unoptimized) *
                              100
                            ).toFixed(2)
                          : (
                              ((pipelinePerf.optimized -
                                pipelinePerf.unoptimized) /
                                pipelinePerf.optimized) *
                              100
                            ).toFixed(2)
                      }%`
                    : `0%`}
                  {pipelinePerf.unoptimized > pipelinePerf.optimized ? (
                    <RiseOutlined
                      style={{
                        marginLeft: 5,
                        fontSize: 15,
                        fontWeight: 600,
                        color: "var(--color-solid-green)",
                      }}
                    />
                  ) : (
                    pipelinePerf.unoptimized < pipelinePerf.optimized && (
                      <FallOutlined
                        style={{
                          marginLeft: 5,
                          fontSize: 15,
                          fontWeight: 600,
                          color: "var(--color-solid-red)",
                        }}
                      />
                    )
                  )}
                </span>
              </Col>
            </Row>
            <Row justify="start" align="middle">
              <Col span={7}>
                <Button
                  loading={pipelineLoader}
                  icon={<BarChartOutlined style={{ fontSize: 16 }} />}
                  type="primary"
                  className="perf-button"
                  onClick={async () => {
                    setPipelinePerf({});
                    setPipelineLoader(true);
                    let startTime = "";
                    let endTime = "";

                    startTime = Date.now();
                    const optimizedPipeline = await contractPipelineApi(
                      1,
                      20,
                      pipelineSwitch,
                      "null",
                      {},
                      userData[0].id
                    );
                    console.log("optimizedPipeline: ", optimizedPipeline);

                    endTime = Date.now();

                    setPipelinePerf({
                      optimizedPipeline: optimizedPipeline,
                      optimized: endTime - startTime,
                    });

                    startTime = Date.now();
                    const oldPipeline = await oldPipelineApi(
                      1,
                      20,
                      pipelineSwitch,
                      userData[0].id
                    );
                    console.log("oldPipeline: ", oldPipeline);

                    endTime = Date.now();

                    setPipelinePerf((prev) => ({
                      ...prev,
                      unOptimizedPipeline: oldPipeline,
                      unoptimized: endTime - startTime,
                    }));

                    setPipelineLoader(false);
                  }}
                >
                  Check Performance
                </Button>
              </Col>
            </Row>
          </Col>
          <Divider />
        </Row>
      </div>
    </Layout>
  );
};

export default DatabaseSeeder;
