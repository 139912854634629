import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Image, Table, Pagination, message } from "antd"; //antd components
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import TopHeader from "../../Header";
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { HeaderChangerAtom } from "../../../store/store";
import { useRecoilState } from "recoil";
import edit from "../../../assets/images/icons/edit_pen.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import { CreateButton } from "../../GlobalButton";
import {
  templatePaginationApi,
  getAllTemplateFiltersApi,
} from "../../../api/contractAuthorityApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { userDataAtom, RoleAccessAtom } from "../../../store/store";
import { deleteTemplate } from "../../../api/contractAuthorityApi";
import { createActivityLogApi } from "../../../api/logApi";
import { getFiltersApi } from "../../../api/usersApi";
import GlobalFilter from "../../GlobalFilter";

const Template = () => {
  const popupModalInstance = PopupModal();
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [tableData, setTableData] = useState([]);
  const menuKey = "5";
  let header = headerChanger.header;
  const navigate = useNavigate();
  const userData = useRecoilState(userDataAtom);
  const [loader, setLoader] = useState(false);
  const roleUser = useRecoilState(RoleAccessAtom); //global state

  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);

  // Variable to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  // Function to handle get all supplier  table data
  useEffect(() => {
    getAllTemplateFiltersApi().then((res) => {
      console.log("filterRes: ", res);
      setFilterDynamic(res);
    });
  }, []);

  const handleTableChange = (_, __, sorter) => {
    /*This if-elseIf combo makes sure 'Updated On' column is always sorted
      in descending order by default and sorting cannot be disabled for this column*/
    if (!sorter.column && sorter.field === "last_updated_on") {
      setSortedColumn("last_updated_on");
      setSortOrder("ascend");
      return;
    } else if (!sorter.column && sorter.field !== "last_updated_on") {
      setSortedColumn("last_updated_on");
      setSortOrder("descend");
      return;
    }

    //This if-else combo applies to other columns and can disable sorting
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res: ", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) =>
                item.contract_authority_template_filter_data
                  ?.template_filter_data
            )
            .filter((n) => n);
        console.log("filterData: ", systemSetupFilterData);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  useEffect(() => {
    getAllFilters();
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      renderTemplate(1, 20, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        renderTemplate(1, 20, allFilterVal);
      } else {
        renderTemplate(1, 20, allFilterVal);
      }
    }
  }, [allFilterVal]);

  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 10,
    total: 0,
  });

  useEffect(() => {
    renderTemplate(1, 10, allFilterVal);
  }, []);

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    handleTableChange("", "", {
      column: { dataIndex: "last_updated_on" },
      order: "descend",
    });
  }, []);

  const handleDeleteTemplate = (
    templateId,
    contract_template_name,
    inputHeaderName
  ) => {
    if (inputHeaderName === contract_template_name) {
      deleteTemplate(templateId).then((res) => {
        let umLog = {
          ss_id: templateId,
          ss_name: contract_template_name,
          code: "delete",
          author: userData[0].id,
          action: "delete-template",
          notes: "Deleted Template",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(
            contract_template_name,
            ModalMessage.TEM_Delete_Success,
            ""
          )
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              renderTemplate(1, 10, allFilterVal);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.TEM_Delete_Error);
    }
  };

  const renderTemplate = (page, limit, allFilterVal) => {
    setLoader(true);
    templatePaginationApi("null", page, limit, allFilterVal).then((res) => {
      console.log("res--", res);
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      let templateData = res.docs.map((data) => {
        let tableRow = {
          key: data.id ? data.id : data._id,
          template_name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates.view
                ) {
                  setHeaderChanger({
                    header: "contractAuthority",
                    headerItem: "2",
                    headerItemName: "contractAuthority",
                    stateSetter: data,
                  });
                  navigate(`/contract_authority/view/template`, {
                    state: data,
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates.view
                    ? "pointer"
                    : "default",
              }}
            >
              {data.contract_template_name || null}
            </span>
          ),
          msa: data.msa_name || null,
          supplier_name: (data && data.supplier_name) || null,
          last_updated_on: moment(data.updated_on).format("DD-MMM-YYYY"),
          status: data.status || null,
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates.view && (
                    <Link
                      id="CreateClauseLibrary"
                      to="/contract_authority/view/template"
                    >
                      <EyeOutlined
                        title="View"
                        style={{
                          fontSize: "20px",
                          color: "var(--color-solid-darkgrey)",
                        }}
                        className="cursor-pointer"
                        onClick={() => {
                          let ssLog = {
                            ss_id: data.id ? data.id : data._id,
                            ss_name: data.contract_template_name,
                            code: "template",
                            author: userData[0].id,
                            action: "view",
                            notes: "viewed contract template",
                            updated_on: new Date(),
                          };
                          createActivityLogApi(ssLog);
                          setHeaderChanger({
                            header: "contractAuthority",
                            headerItem: "2",
                            headerItemName: "contractAuthority",
                            stateSetter: data,
                          });
                          navigate(`/contract_authority/view/template`, {
                            state: data,
                          });
                        }}
                      />
                    </Link>
                  )}{" "}
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates
                    .create && (
                    <Image
                      title="Edit"
                      src={edit}
                      preview={false}
                      style={{ width: "20px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/contract_authority/edit/template", {
                          state: data,
                        });
                      }}
                    />
                  )}{" "}
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.contract_authority.templates
                    .create && (
                    <Image
                      title="Delete"
                      src={deleteIcon}
                      preview={false}
                      style={{ width: "20px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        popupModalInstance
                          .confirModal(ModalMessage.TEM_Delete_Info)
                          .then(async (userchoice) => {
                            if (userchoice.action === "delete") {
                              handleDeleteTemplate(
                                data.id ? data.id : data._id,
                                data.contract_template_name,
                                userchoice.inputValue
                              );
                            }
                          });
                      }}
                    />
                  )}
              </div>
            </>
          ),
        };
        return tableRow;
      });
      setTableData(templateData);
    });
  };

  const onPageChange = (page, limit) => {
    console.log("page", page);
    console.log("limit", limit);
    renderTemplate(page, limit, allFilterVal);
  };

  const col = [
    {
      title: renderColumnTitle("Template Name", "template_name"),
      dataIndex: "template_name",
      key: "template_name",
      align: "left",
      sorter: (a, b) =>
        a.template_name.props.children.localeCompare(
          b.template_name.props.children
        ),
      sortOrder: isColumnSorted("template_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Name", "supplier_name"),
      dataIndex: "supplier_name",
      key: "supplier_name",
      align: "left",
      // sorter: (a, b) =>
      //   a.supplier_name.props.children.localeCompare(
      //     b.supplier_name.props.children
      //   ),
      sorter: (a, b) => {
        const valueA = a.supplier_name;
        const valueB = b.supplier_name;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("MSA", "msa"),
      dataIndex: "msa",
      key: "msa",
      align: "left",
      // sorter: (a, b) =>
      //   a.msa.props.children.localeCompare(b.msa.props.children),
      sorter: (a, b) => {
        const valueA = a.msa;
        const valueB = b.msa;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("msa") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Last Updated On", "last_updated_on"),
      dataIndex: "last_updated_on",
      key: "last_updated_on",
      align: "left",
      sorter: (a, b) =>
        moment(a.last_updated_on).unix() - moment(b.last_updated_on).unix(),
      sortOrder: isColumnSorted("last_updated_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      key: "status",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.status;
        const valueB = b.status;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },

    // {
    //   title: "Supplement",
    //   dataIndex: "supplement",
    //   key: "supplement",
    // },
    // {
    //   title: "CCN",
    //   dataIndex: "ccn",
    //   key: "ccn",
    // },
    // {
    //   title: "SOW",
    //   dataIndex: "sow",
    //   key: "sow",
    // },
    // {
    //   title: "CR",
    //   dataIndex: "cr",
    //   key: "cr",
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.contract_authority.templates.view ||
          roleUser[0].page_access.contract_authority.templates.create)
          ? true
          : false,
    },
  ];

  const colToShow = col.filter((col) => col.show !== false);

  useEffect(() => {
    document.title = "Templates | Judo";
  }, []);

  return (
    <Layout className="header-layout">
      {saveMessageContextHolder}
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <div className="contract-pipeline">
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.templates.create
                ? 15
                : 24
            }
            lg={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.templates.create
                ? 15
                : 24
            }
            xl={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.contract_authority.templates.create
                ? 22
                : 24
            }
          >
            <GlobalFilter
              selectedTab={"template"}
              initialTableData={tableData}
              allFilterVal={allFilterVal}
              setAllFilterVal={setAllFilterVal}
            />
          </Col>

          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.contract_authority.templates.create && (
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <Link
                  id="CreateClauseLibrary"
                  to="/contract_authority/create/template"
                >
                  <CreateButton />
                </Link>
              </Col>
            )}
        </Row>

        <Row>
          <Col span={24}>
            <Table
              rowClassName={() => "contract-tree-table-row"}
              dataSource={tableData}
              columns={colToShow}
              onChange={handleTableChange}
              showSorterTooltip={false}
              bordered
              size="large "
              scroll={{ x: "calc(300px + 50%)", y: 400 }}
              pagination={false}
              className="system-setup-table mt-10"
            />
            <div className="float-right mb-15 mt-15">
              <Pagination
                // simple
                // pageSizeOptions={[5, 10, 20, 50, 100]}
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Template;
