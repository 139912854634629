import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Pagination } from "antd"; //antd components
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import moment from "moment";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
  LegalEntityUpdateAtom,
  LegalEntityAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import { Link, useNavigate } from "react-router-dom";
import {
  listLegalEntityPagination,
  deleteLegalEntityApi,
  getAllLegalFiltersApi,
  allLegalEntitysApi,
} from "../../../api/legalEntityApi";
import { CreateButton } from "../../GlobalButton";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import delete_disable from "../../../assets/images/icons/delete_disable.png";
import ActionButton from "../../ActionButton";
import GlobalFilter from "../../GlobalFilter";

function LegalEntitySetup() {
  const popupModalInstance = PopupModal();
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const [legalEntityName, setLegalEntityName] = useRecoilState(LegalEntityAtom);
  // Variable to use navigate function
  const navigate = useNavigate();
  // State variable to hold legal entity data
  const [legalEntityUpdate, setLegalEntityUpdate] = useRecoilState(
    LegalEntityUpdateAtom
  );
  // Variable to hold selected legal entity column
  let selectedCol = legalEntityUpdate.selectedCol;
  // Variable to hold unselected legal entity column
  let unSelectedCol = legalEntityUpdate.unSelectedCol;
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold column editer visibility status
  const [colEditor, openColEditor] = useState(false);
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // State variable to hold  header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    /*This if-elseIf combo makes sure 'Updated On' column is always sorted
      in descending order by default and sorting cannot be disabled for this column*/
    if (!sorter.column && sorter.field === "updated_on") {
      setSortedColumn("updated_on");
      setSortOrder("ascend");
      return;
    } else if (!sorter.column && sorter.field !== "updated_on") {
      setSortedColumn("updated_on");
      setSortOrder("descend");
      return;
    }

    //This if-else combo applies to other columns and can disable sorting
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold a all column and style
  const allColumns = [
    {
      title: renderColumnTitle("Legal Entity Name", "legal_entity_name"),
      dataIndex: "legal_entity_name",
      align: "left",
      width: 400,
      sorter: (a, b) =>
        a.legal_entity_name.props.children.localeCompare(
          b.legal_entity_name.props.children
        ),
      sortOrder: isColumnSorted("legal_entity_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Legal Entity Country", "legal_entity_country"),
      dataIndex: "legal_entity_country",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.legal_entity_country;
        const valueB = b.legal_entity_country;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("legal_entity_country") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Legal Entity Address", "legal_entity_address"),
      dataIndex: "legal_entity_address",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.legal_entity_address;
        const valueB = b.legal_entity_address;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("legal_entity_address") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.status;
        const valueB = b.status;
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.updated_by?.toLowerCase();
        const valueB = b.updated_by?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("updated_by") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: (a, b) => {
        const dateA = new Date(a.updated_on);
        const dateB = new Date(b.updated_on);
        if (isNaN(dateA) || isNaN(dateB)) {
          return 0;
        }
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("updated_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.legal_entity_setup.view ||
          roleUser[0].page_access.system_setup.legal_entity_setup.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columnToShow = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleViewLegalEntity = (data) => {
    let ssLog = {
      ss_id: data.id ? data.id : data._id,
      ss_name: data.le_name,
      code: "legal_entity",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Legal Entity",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };
  /* Function to handle the get legal entity data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const renderLegalEntity = (page, limit, allFilterVal) => {
    setLoader(true);
    listLegalEntityPagination(page, limit, allFilterVal).then((res) => {
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      let legalEntityMaster = res.docs.map((data) => {
        let tableRow = {
          key: data.id ? data.id : data._id,
          legal_entity_id: data.id ? data.id : data._id,
          legal_entity_name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.legal_entity_setup.view
                ) {
                  handleViewLegalEntity(data);
                  setHeaderChanger({
                    header: "systemSetup",
                    headerItem: "1",
                    headerItemName: "systemSetup",
                    stateSetter: data,
                  });
                  navigate(`/organization-setup/view/legal-entity`, {
                    state: { data, flag: "view" },
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.legal_entity_setup.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.legal_entity_setup.view
                    ? "pointer"
                    : "default",
              }}
            >
              {data.le_name}
            </span>
          ),
          legal_entity_country: data.le_country ? data.le_country : "-",
          legal_entity_address: data.le_address ? data.le_address : "-",
          status: data.le_status,
          created_on: moment(data.createdAt).format("DD-MMM-YY"),
          created_by: data.created_user_name,
          updated_on: moment(data.updatedAt).format("DD-MMM-YY"),
          updated_by: data.updated_user_name,
          action: (
            <ActionButton
              AccessCheck={
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.system_setup.legal_entity_setup
              }
              data={data}
              dataStatus={data.le_status}
              itemName={data.le_name}
              setHeaderChanger={setHeaderChanger}
              headerValue={{
                header: "systemSetup",
                headerItem: "1",
                headerItemName: "systemSetup",
              }}
              menuItem="organization-setup"
              subMenuItem="legal-entity"
              deleteInfo={ModalMessage.LE_Delete_Info}
              handleDelete={handleDeleteLe}
              handleView={handleViewLegalEntity}
            />
          ),
        };
        return tableRow;
      });
      console.log("legalEntityMaster: ", legalEntityMaster);
      setTableData(legalEntityMaster);
    });
  };

  // store legalEntity name in global state
  const getAllLegalEntity = async () => {
    await allLegalEntitysApi().then((res) => {
      setLegalEntityName(res.map((i) => i.le_name));
    });
  };

  const handleDeleteLe = (leId, le_name, inputLeName) => {
    if (inputLeName === le_name) {
      deleteLegalEntityApi(leId).then((res) => {
        let umLog = {
          ss_id: leId,
          ss_name: le_name,
          code: "delete",
          author: userData[0].id,
          action: "delete-le",
          notes: "Deleted Legal Entity",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(le_name, ModalMessage.LE_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              renderLegalEntity(1, 10);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.LE_Delete_Error);
    }
  };

  /* Function to handle change the page number
   * @param page -  new page number.
   * @param limit - set a limit of data dor this page
   */
  const onPageChange = (page, limit) => {
    renderLegalEntity(page, limit, allFilterVal);
  };

  /* Function to handle select the column
   * @Param currency - a select column data
   */
  const selectCol = (currency) => {
    setLegalEntityUpdate({
      selectedCol: [...selectedCol, currency],
      unSelectedCol: unSelectedCol.filter((col) => col !== currency),
    });
  };

  /* Function to handle unselect the column
   * @Param uncurrency - a select column data
   */
  const unSelectCol = (currency) => {
    setLegalEntityUpdate({
      unSelectedCol: [...unSelectedCol, currency],
      selectedCol: selectedCol.filter((col) => col !== currency),
    });
  };

  useEffect(() => {
    getAllLegalEntity();
  }, []);

  // Function to handle get all legal entity
  useEffect(() => {
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
   * allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      renderLegalEntity(1, 20, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        renderLegalEntity(1, 20, allFilterVal);
      } else {
        renderLegalEntity(1, 20, allFilterVal);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Legal Entity Setup | Judo";
  }, []);

  const onCreateLegalEntity = () => {
    navigate(`/organization-setup/create/legal-entity`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.legal_entity_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.legal_entity_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.legal_entity_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"legalEntity"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.legal_entity_setup.create && (
            <Col xl={2} md={6} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateLegalEntity} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columnToShow}
          onChange={handleTableChange}
          bordered
          size="large"
          loading={loader}
          scroll={{
            x: "calc(500px + 50%)",
            y: "calc(100vh - 255px)",
          }}
          pagination={false}
          className="system-setup-table"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>

      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => openColEditor(false)}
          onCancel={() => openColEditor(false)}
          closable={false}
          footer={null}
        >
          <div>
            <span className="col-editor-head">
              Select the tag to show in Legal Entity Setup.
            </span>
            <span
              className="float-right col-editor-save"
              onClick={() => openColEditor(false)}
            >
              save
            </span>
          </div>
          <div className="col-list p-10 mt-15">
            <Row gutter={[8, 16]}>
              {selectedCol &&
                selectedCol.length !== 0 &&
                selectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="selected-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        unSelectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
            <Row className="mt-20" gutter={[8, 16]}>
              {unSelectedCol &&
                unSelectedCol.length !== 0 &&
                unSelectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="select-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        selectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default LegalEntitySetup;
