import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Spin,
  Badge,
  Space,
  Pagination,
  Tabs,
  Select,
  Checkbox,
  Tag,
  Form,
  message,
  DatePicker,
} from "antd"; //antd components
import {
  UploadOutlined,
  EyeOutlined,
  FilterOutlined,
  SearchOutlined,
  UpOutlined,
  DownOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  userDataAtom,
} from "../../../store/store";
import { createActivityLogApi } from "../../../api/logApi";
import searchIcon from "../../../assets/images/icons/search.svg";
import add from "../../../assets/images/icons/Add_round.svg";
import edit from "../../../assets/images/icons/edit_pen.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import save_filter from "../../../assets/images/icons/save_filter.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import { CreateButton } from "../../GlobalButton";
import delete_disable from "../../../assets/images/icons/delete_disable.png";

import { Link, useNavigate } from "react-router-dom";
import { LegalEntityUpdateAtom, CostCenterAtom } from "../../../store/store";
import {
  CostCenterPagination,
  deleteCostCenterApi,
  deleteCCApi,
  getAllCostFiltersApi,
  costCenterOnlyApi,
} from "../../../api/costCenterApi";
import { createFiltersApi, getFiltersApi } from "../../../api/usersApi";
import moment from "moment";
import dayjs from "dayjs";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import ActionButton from "../../ActionButton";
import GlobalFilter from "../../GlobalFilter";

function CostCenterSetup() {
  const popupModalInstance = PopupModal();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const navigate = useNavigate();
  //state varaiable hold the recoil value about supplier setup
  const [legalEntityUpdate, setLegalEntityUpdate] = useRecoilState(
    LegalEntityUpdateAtom
  );
  const [costCenterName, setCostCenterName] = useRecoilState(CostCenterAtom);
  //state avraible hold the selectedd column data
  let selectedCol = legalEntityUpdate.selectedCol;
  //state avraible hold the unselectedd column data
  let unSelectedCol = legalEntityUpdate.unSelectedCol;
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold the editor true or false
  const [colEditor, openColEditor] = useState(false);
  // State variable to hold selected row key
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold selected saved filter conditions
  const [selectedSavedFilter, setSelectedSavedFilter] = useState();
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold saved filter conditions
  const [savedFilters, setSavedFilters] = useState([]);
  // State variable to hold column data visibility status
  const [colFilterShow, setColFilterShow] = useState(false);
  // State variable to hold inner filter data
  const [innerFilterShow, setInnerFilterShow] = useState();
  // State variable to hold save dialog visibility status
  const [saveDialog, setSaveDialog] = useState(false);
  // State variable to hold save filter visibility status
  const [savedFilterShow, setSavedFilterShow] = useState(false);
  // State variable to hold filter category for public & private
  const [pubPvt, setPubPvt] = useState("public");
  // State variable to hold dynamic filter data
  const [filterDynamic, setFilterDynamic] = useState([]);
  // State variable to hold search filter data
  const [filterSearchVal, setFilterSearchVal] = useState("null");
  // State variable to hold save icon visibility status
  const [showSaveIcon, setShowSaveIcon] = useState(false);
  // State variable to hold selected filter condition count
  const [filterCount, setFilterCount] = useState(0);
  // State variable to hold save message context holder
  const [messageApi, saveMessageContextHolder] = message.useMessage();
  // Variable to hold group check box
  const CheckboxGroup = Checkbox.Group;
  const { RangePicker } = DatePicker;

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    /*This if-elseIf combo makes sure 'Updated On' column is always sorted
      in descending order by default and sorting cannot be disabled for this column*/
    if (!sorter.column && sorter.field === "updated_on") {
      setSortedColumn("updated_on");
      setSortOrder("ascend");
      return;
    } else if (!sorter.column && sorter.field !== "updated_on") {
      setSortedColumn("updated_on");
      setSortOrder("descend");
      return;
    }

    //This if-else combo applies to other columns and can disable sorting
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Cost Center Name", "cost_center_name"),
      dataIndex: "cost_center_name",
      align: "left",
      width: 400,
      sorter: (a, b) =>
        a.cost_center_name.props.children.localeCompare(
          b.cost_center_name.props.children
        ),
      sortOrder: isColumnSorted("cost_center_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Cost Center Number", "cost_center_number"),
      dataIndex: "cost_center_number",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.cost_center_number?.toLowerCase();
        const valueB = b.cost_center_number?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("cost_center_number") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Legal Entity Name", "legal_entity_name"),
      dataIndex: "legal_entity_name",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.legal_entity_name?.toLowerCase();
        const valueB = b.legal_entity_name?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("legal_entity_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("CC Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.status?.toLowerCase();
        const valueB = b.status?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      sorter: (a, b) => {
        const valueA = a.updated_by?.toLowerCase();
        const valueB = b.updated_by?.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      },
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      sorter: (a, b) => {
        const dateA = new Date(a.updated_on);
        const dateB = new Date(b.updated_on);
        if (isNaN(dateA) || isNaN(dateB)) {
          return 0;
        }
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      },
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.cost_center_setup.view ||
          roleUser[0].page_access.system_setup.cost_center_setup.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columnToShow = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleviewCostCenter = (item) => {
    let ssLog = {
      ss_id: item.id ? item.id : item._id,
      ss_name: item.cc_name,
      code: "cost_center",
      author: userData[0].id,
      action: "view",
      notes: "Viewed Cost Center",
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {});
  };

  /* Function to handle the get Fx table data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const renderCostCenter = (page, limit, searchVal, allFilterVal) => {
    setLoader(true);
    CostCenterPagination(page, limit, searchVal, allFilterVal).then((res) => {
      setLoader(false);
      console.log("res--", res);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const costCenterMaster = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          cost_center_id: item.id ? item.id : item._id,
          cost_center_name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.cost_center_setup.view
                ) {
                  handleviewCostCenter(item);
                  setHeaderChanger({
                    header: "systemSetup",
                    headerItem: "1",
                    headerItemName: "systemSetup",
                    stateSetter: item,
                  });
                  navigate(`/organization-setup/view/cost-center`, {
                    state: { data: item, flag: "view" },
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.cost_center_setup.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.cost_center_setup.view
                    ? "pointer"
                    : "default",
              }}
            >
              {item.cc_name}
            </span>
          ),
          cost_center_number: item.cc_nbr ? item.cc_nbr : "-",
          status: item.cc_status,
          legal_entity_name: item.le_name ? item.le_name : "-",
          created_on: moment(item.createdAt).format("DD-MMM-YY"),
          created_by: item.created_user_name,
          updated_on: moment(item.updatedAt).format("DD-MMM-YY"),
          updated_by: item.updated_user_name,
          action: (
            <ActionButton
              AccessCheck={
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.system_setup.cost_center_setup
              }
              data={item}
              dataStatus={item.cc_status}
              itemName={item.cc_name}
              setHeaderChanger={setHeaderChanger}
              headerValue={{
                header: "systemSetup",
                headerItem: "1",
                headerItemName: "systemSetup",
              }}
              menuItem="organization-setup"
              subMenuItem="cost-center"
              deleteInfo={ModalMessage.CC_Delete_Info}
              handleDelete={handleDeleteCC}
              handleView={handleviewCostCenter}
            />
          ),
        };
        return tableRow;
      });
      console.log("costCenterMaster: ", costCenterMaster);
      setTableData(costCenterMaster);
    });
  };

  const handleDeleteCC = (ccId, cc_name, inputCCName) => {
    if (inputCCName === cc_name) {
      deleteCCApi(ccId).then((res) => {
        let umLog = {
          ss_id: ccId,
          ss_name: cc_name,
          code: "delete",
          author: userData[0].id,
          action: "delete-cc",
          notes: "Deleted Cost Center",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(cc_name, ModalMessage.CC_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              renderCostCenter(1, 20, searchVal);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.CC_Delete_Error);
    }
  };

  // store costcenter_name in global state
  const getAllCostCenter = async () => {
    await costCenterOnlyApi().then((res) => {
      setCostCenterName(res.map((i) => i.cc_name));
    });
  };

  useEffect(() => {
    getAllCostCenter();
  }, []);

  useEffect(() => {
    renderCostCenter(1, 20, searchVal);

    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, [searchVal]);

  const onPageChange = (page, limit) => {
    renderCostCenter(page, limit, searchVal, allFilterVal);
  };

  // delete cost center
  const deleteCostCenter = (id) => {
    deleteCostCenterApi(id).then((res) => {
      console.log("ressss", res);
      renderCostCenter();
    });
  };

  const rowClick = (rowId) => {
    console.log("rowId", rowId);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectCol = (currency) => {
    setLegalEntityUpdate({
      selectedCol: [...selectedCol, currency],
      unSelectedCol: unSelectedCol.filter((col) => col !== currency),
    });
  };

  const unSelectCol = (currency) => {
    setLegalEntityUpdate({
      unSelectedCol: [...unSelectedCol, currency],
      selectedCol: selectedCol.filter((col) => col !== currency),
    });
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log("searchValue", searchValue);
    if (searchValue === "") {
      setSearchVal("null");
    } else {
      setSearchVal(searchValue);
    }
  };

  // Function to handle get all costcenter table data
  useEffect(() => {
    getAllCostFiltersApi().then((res) => {
      console.log("res", res);
      setFilterDynamic(res);
    });
  }, []);

  const colFilterVal =
    filterDynamic &&
    filterDynamic.length !== 0 &&
    filterDynamic.map((item) => ({
      value: item.name,
      label: item.name,
    }));

  /* Function to handle to search the saved filters
   * @param e - the input filter data
   */
  const onSearchFilterVal = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log("searchValue", searchValue);
    if (searchValue === "") {
      setFilterSearchVal("null");
    } else {
      setFilterSearchVal(searchValue);
    }
  };

  /* Function to handle select filter
   * @param key - Contains label and key of the selected option
   * @param value - Contains value of the selected option
   */
  const onCloseTag = (key, value) => {
    let emptyFilVal = {
      ...allFilterVal,
      [`${key}`]: allFilterVal[`${key}`].filter((val) => val.key !== value),
    };
    Object.keys(emptyFilVal).forEach((key) => {
      if (emptyFilVal[key].length === 0) {
        delete emptyFilVal[key];
      }
    });
    setAllFilterVal(emptyFilVal);
  };

  // Function to handle the filter save visibility
  const onClickSave = () => {
    setInnerFilterShow(false);
    setColFilterShow(false);
    setSaveDialog(!saveDialog);
  };

  /* Function to handle the create costcenter form data stored
   * @param values - a user create form values
   */
  const onSave = async (values) => {
    setSaveDialog(false);
    const data = {
      filter_type: values.filter_type,
      filter_name: values.filter_name,
      filter_values: allFilterVal,
    };
    await createFiltersApi({
      created_by: userData[0].id,
      system_setup_filter_data: { cost_center_filter: [data] },
      created_on: new Date(),
      updated_on: new Date(),
    }).then((res) => {
      messageApi.open({
        type: "success",
        content: (
          <span>
            <span style={{ fontWeight: 600 }}>{values.filter_name} </span>
            {values.filter_type} filter has been saved successfully
          </span>
        ),
      });
      console.log("filter saved");
      getAllFilters();
    });
  };

  // Function to get a all filter details from db
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res
            .flatMap(
              (item) => item.system_setup_filter_data?.cost_center_filter
            )
            .filter((n) => n);
        console.log("filterData: ", systemSetupFilterData);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    getAllFilters();
  }, []);

  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      setShowSaveIcon(false);
      renderCostCenter(1, 20, searchVal, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        setShowSaveIcon(false);
        renderCostCenter(1, 20, searchVal, allFilterVal);
      } else {
        setShowSaveIcon(true);
      }
    }

    setFilterCount(
      Object.keys(allFilterVal || {}).filter(
        (a) => allFilterVal[`${a}`].length !== 0
      ).length
    );

    renderCostCenter(1, 20, searchVal, allFilterVal);
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Cost Center Setup | Judo";
  }, []);

  const onCreateCostCenter = () => {
    navigate(`/organization-setup/create/cost-center`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      {saveMessageContextHolder}
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.cost_center_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"costCenter"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
          {/* <div className="filter-white-space-head">
            <Col
              className="p-0 vertical-align"
              xs={24}
              sm={24}
              md={1}
              lg={1}
              xl={1}
              style={{ background: "white" }}
            >
              <Image
                className="cursor-pointer saved-filter-image"
                src={save_filter}
                preview={false}
                onClick={() => {
                  setColFilterShow(false);
                  setInnerFilterShow(null);
                  setSaveDialog(false);
                  setSavedFilterShow(!savedFilterShow);
                }}
              />
              {savedFilterShow && tableData.length !== 0 && (
                <div className="filter-div">
                  <Tabs
                    id="ss-filter-tabs"
                    className="ss-saved-filter-tabs"
                    defaultActiveKey={pubPvt}
                    items={[
                      {
                        key: "public",
                        label: `Public`,
                      },
                      {
                        key: "private",
                        label: `Private`,
                      },
                    ]}
                    onChange={(key) => setPubPvt(key)}
                  />
                  <Select
                    className="filter-select"
                    showSearch
                    placeholder="Search"
                    optionFilterProp="children"
                    open={true}
                    dropdownStyle={{
                      padding: "0",
                      boxShadow: "none",
                    }}
                    listItemHeight={0}
                    listHeight={195}
                    onChange={(val, key) => {
                      console.log("val ret***", val);
                      console.log("key ret***", key);
                      setColFilterShow(false);
                      setSaveDialog(false);
                      setSavedFilterShow(!savedFilterShow);
                      setSelectedSavedFilter(key.label);
                      setAllFilterVal(
                        savedFilters
                          .filter((data) => data.filter_name === key.label)
                          .map((v) => v.filter_values)[0]
                      );
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      savedFilters &&
                      savedFilters
                        .filter((x) => x.filter_type === pubPvt)
                        .map((item, i) => {
                          console.log("saved fil ret****", item);
                          console.log("pubPvt ret****", pubPvt);
                          return { label: item.filter_name, value: i };
                        })
                    }
                  />
                </div>
              )}
            </Col>
            <Col
              className="p-0 vertical-align"
              xs={24}
              sm={24}
              md={1}
              lg={1}
              xl={1}
              style={{ background: "white" }}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  setColFilterShow(!colFilterShow);
                  setInnerFilterShow(null);
                  setSaveDialog(false);
                  setSavedFilterShow(false);
                }}
              >
                <Badge count={filterCount}>
                  <FilterOutlined className="cursor-pointer filter-image" />
                </Badge>
              </div>

              {colFilterShow && tableData.length !== 0 && (
                <div className="filter-div">
                  <Select
                    className="filter-select"
                    showSearch
                    placeholder="Search"
                    optionFilterProp="children"
                    open={true}
                    dropdownStyle={{ padding: "0", boxShadow: "none" }}
                    onChange={(val) => {
                      console.log("SelectVal: ", val);
                      setColFilterShow(!colFilterShow);
                      setInnerFilterShow(val);
                      setSaveDialog(false);
                      setSavedFilterShow(false);
                    }}
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={colFilterVal}
                  />
                </div>
              )}

              {filterDynamic &&
                filterDynamic.length !== 0 &&
                filterDynamic.map((item) => {
                  if (innerFilterShow === "Updated On") {
                    return (
                      <div className="filter-inner-div-1">
                        <div className="filter-title">
                          <span style={{ textTransform: "capitalize" }}>
                            {innerFilterShow}
                          </span>
                          <CloseCircleOutlined
                            style={{
                              fontSize: 17,
                              color: "var(--color-border)",
                            }}
                            onClick={() => {
                              setInnerFilterShow(null);
                              setColFilterShow(true);
                            }}
                          />
                        </div>
                        <RangePicker
                          className="range-picker"
                          format="DD MMM YYYY"
                          value={
                            Object.keys(allFilterVal || {}).includes(
                              innerFilterShow
                            ) &&
                            allFilterVal[innerFilterShow] &&
                            allFilterVal[innerFilterShow].length !== 0 &&
                            allFilterVal[innerFilterShow][0].key.map((date) =>
                              dayjs(date, "DD MMM YYYY")
                            )
                          }
                          onChange={(moment, val) => {
                            console.log("moment", moment);
                            console.log("val", val);
                            if (moment != null) {
                              const updatedFilterVal = {
                                ...allFilterVal,
                                [innerFilterShow]: [
                                  {
                                    key: val,
                                    value: val,
                                  },
                                ],
                              };
                              setAllFilterVal(updatedFilterVal);
                            } else if (moment === null) {
                              setAllFilterVal((prevState) => ({
                                ...prevState,
                                [innerFilterShow]: [],
                              }));
                            }
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <>
                        {innerFilterShow === item.name && (
                          <div className="filter-inner-div">
                            <div className="filter-title">
                              <span style={{ textTransform: "capitalize" }}>
                                {item.name}
                              </span>{" "}
                              <CloseCircleOutlined
                                style={{
                                  fontSize: 17,
                                  color: "var(--color-border)",
                                }}
                                onClick={() => {
                                  setInnerFilterShow(null);
                                  setColFilterShow(true);
                                }}
                              />
                            </div>
                            <div>
                              <Input
                                onChange={onSearchFilterVal}
                                placeholder="Search"
                                suffix={
                                  <Image
                                    preview={false}
                                    className="search-icon"
                                    src={searchIcon}
                                    width={30}
                                  />
                                }
                                className="pp-search-input mt-5"
                              />
                            </div>
                            <CheckboxGroup
                              value={
                                Object.keys(allFilterVal || {}).includes(
                                  item.name
                                ) &&
                                allFilterVal[item.name] &&
                                allFilterVal[item.name].length !== 0 &&
                                allFilterVal[item.name].map((data) => data.key)
                              }
                              options={
                                filterSearchVal === "null"
                                  ? item.filterValues.map((val) => ({
                                      label: val.label ? val.label : val.value,
                                      value: val.key,
                                      key: val.key,
                                    }))
                                  : item.filterValues
                                      .filter(
                                        (data) =>
                                          JSON.stringify(data)
                                            .toLowerCase()
                                            .indexOf(
                                              filterSearchVal.toLowerCase()
                                            ) !== -1
                                      )
                                      .map((val) => ({
                                        label: val.label
                                          ? val.label
                                          : val.value,
                                        value: val.key,
                                        key: val.key,
                                      }))
                              }
                              onChange={(val) => {
                                let data = [];
                                val.map((key) => {
                                  item.filterValues
                                    .filter((option) => key === option.key)
                                    .map((obj) => {
                                      data.push(obj);
                                    });
                                });
                                const updatedFilterVal = {
                                  ...allFilterVal,
                                  [item.name]: data,
                                };
                                setAllFilterVal(updatedFilterVal);
                              }}
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                })}
            </Col>
            <Space
              className="filter-white-space"
              style={{ width: "90%" }}
              size={[0, 8]}
            >
              {Object.keys(allFilterVal || {}).length !== 0 ? (
                Object.keys(allFilterVal || {}).map((filkey) => {
                  if (
                    allFilterVal[filkey] &&
                    allFilterVal[filkey].length !== 0
                  ) {
                    return allFilterVal[filkey].map((fildata) => (
                      <div key={`${filkey}-${fildata.key}`}>
                        <Tag key={`${filkey}-${fildata.key}`}>
                          <span style={{ textTransform: "capitalize" }}>
                            {filkey}:{" "}
                            {filkey === "Updated On"
                              ? `${fildata.value[0]} - ${fildata.value[1]}`
                              : fildata.label
                              ? fildata.label
                              : fildata.value}
                          </span>
                          <span
                            onClick={() => onCloseTag(filkey, fildata.key)}
                            className="cursor-pointer"
                            style={{ paddingLeft: "5px" }}
                          >
                            <CloseCircleOutlined />
                          </span>
                        </Tag>
                      </div>
                    ));
                  }
                })
              ) : (
                <p className="placeholder-text">Add Filters</p>
              )}
            </Space>

            <Space
              className="filter-white-space-icons"
              style={{ width: "10%" }}
              size={[0, 8]}
            >
              {showSaveIcon && (
                <>
                  <SaveOutlined onClick={() => onClickSave()} />
                  <CloseOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setSelectedSavedFilter();
                      setAllFilterVal({});
                      setSaveDialog(false);
                    }}
                  />
                </>
              )}
            </Space>
          </div> */}

          {/* {saveDialog && showSaveIcon && (
            <div className="filter-inner-div save-dialog-box">
              <div className="filter-title">
                <span>Save Filter</span>{" "}
                <CloseCircleOutlined
                  style={{
                    fontSize: 17,
                    color: "var(--color-border)",
                  }}
                  onClick={() => {
                    setSaveDialog(false);
                  }}
                />
              </div>
              <div className="inner-div">
                <Form onFinish={onSave}>
                  <Form.Item
                    name="filter_type"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Filter Type !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Filter Type"
                      options={[
                        {
                          value: "public",
                          label: "Public",
                        },
                        {
                          value: "private",
                          label: "Private",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    name="filter_name"
                    className="m-0"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Filter Name !",
                      },
                    ]}
                  >
                    <Input placeholder="Filter Name" />
                  </Form.Item>
                  <span className="buttons">
                    <p onClick={() => setSaveDialog(false)}>Cancel</p>
                    <Button
                      htmlType="submit"
                      style={{
                        padding: "0",
                        border: "0",
                        height: "auto",
                      }}
                    >
                      <p>Save</p>
                    </Button>
                  </span>
                </Form>
              </div>
            </div>
          )} */}
        </Col>
        {/* <Col
          className="p-1 vertical-align"
          xs={24}
          sm={24}
          md={5}
          lg={5}
          xl={5}
        >
          <Input
            placeholder="Find in Page"
            className="pipeline-search"
            onChange={onChangeSearch}
            suffix={<SearchOutlined style={{ color: "#B0B0B0" }} />}
          />
        </Col> */}
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.cost_center_setup.create && (
            <Col xl={2} md={6} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateCostCenter} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          // rowSelection={rowSelection}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columnToShow}
          onChange={handleTableChange}
          bordered
          size="large "
          loading={loader}
          scroll={{
            x: selectedCol.length !== 0 ? 1600 : 1000,
            y: "calc(100vh - 265px)",
          }}
          pagination={false}
          className="system-setup-table"
          // onRow={(record, rowIndex) => {
          //   return {
          //     // onClick: () => { rowClick(record.key) },
          //     onMouseOver: () => {
          //       setShowEditOf(record.key);
          //     },
          //     onMouseOut: () => {
          //       setShowEditOf(null);
          //     },
          //   };
          // }}
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            // simple
            // pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>

      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => openColEditor(false)}
          onCancel={() => openColEditor(false)}
          closable={false}
          footer={null}
        >
          <div>
            <span className="col-editor-head">
              Select the tag to show in Legal Entity Setup.
            </span>
            <span
              className="float-right col-editor-save"
              onClick={() => openColEditor(false)}
            >
              save
            </span>
          </div>
          <div className="col-list p-10 mt-15">
            <Row gutter={[8, 16]}>
              {selectedCol &&
                selectedCol.length !== 0 &&
                selectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="selected-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        unSelectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
            <Row className="mt-20" gutter={[8, 16]}>
              {unSelectedCol &&
                unSelectedCol.length !== 0 &&
                unSelectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="select-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        // selectedCol.push(col);
                        selectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default CostCenterSetup;
