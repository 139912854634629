// External Imports
import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Button,
  Tree,
  Layout,
  Table,
  Pagination,
  Modal,
  Space,
} from "antd";
import {
  EyeOutlined,
  FileOutlined,
  DownOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useRecoilValue } from "recoil";

// Internal Imports
import FolderImg from "../../../assets/images/icons/folder.svg";
import {
  SupplierTreeDataAtom,
  RoleAccessAtom,
  userDataAtom,
  HeaderChangerAtom,
} from "../../../store/store";
import "../../../assets/style/setup.css";
import "../../../assets/style/contracttree.css";
import frameworkAgreement from "../../../assets/images/icons/framework_agreement.svg";
import supplement from "../../../assets/images/icons/supplement.svg";
import sow from "../../../assets/images/icons/sow.svg";
import ccn from "../../../assets/images/icons/ccn.svg";
import edit from "../../../assets/images/icons/edit_pen.svg";
import edit_disable from "../../../assets/images/icons/edit_disable.png";
import clone_disable from "../../../assets/images/icons/clone_disable.png";
import confirmation from "../../../assets/images/icons/confirmation.svg";
import { createActivityLogApi } from "../../../api/logApi";
import account_tree from "../../../assets/images/icons/account_tree.svg";
import {
  getActionByContractApi,
  getActionBySupplierApi,
  getAllActionPaginationApi,
} from "../../../api/actionManagementApi";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import { CancelButton, CreateButton } from "../../GlobalButton";

// Supplier-Tree render function
const { TreeNode } = Tree;
const renderTreeNodes = (data) => {
  data.map((item) => {
    if (
      item.children !== "undefined" && item.children
        ? item.children.length !== 0
        : ""
    ) {
      return (
        <>
          <TreeNode
            title={
              <div
                className="title-div"
                style={{ display: "inline-block", width: "100%" }}
              >
                <span>
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>
                {/* <span className="pin">
                  {item.pinned ? (
                    <PushpinFilled
                      style={{ fontSize: "11px", marginRight: "1px" }}
                    />
                  ) : (
                    ""
                  )}
                </span> */}
              </div>
            }
            icon={
              item.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : item.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : item.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <FileOutlined />
                </>
              )
            }
            key={item._id}
            id={item._id}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        </>
      );
    }
    return (
      <TreeNode
        title={
          <span>
            {item.contract_name ? item.contract_name : item.supplier_name}
          </span>
        }
        key={item._id}
        id={item._id}
        icon={
          item.contract_type === "Supplier" ? (
            <>
              <span>
                <Image src={FolderImg} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "MSA" ? (
            <>
              <span>
                <Image src={frameworkAgreement} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "Sub-Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "SOW" ? (
            <>
              <span>
                <Image src={sow} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "CCN" ? (
            <>
              <span>
                <Image src={ccn} preview={false} width={20} />
              </span>
            </>
          ) : (
            <>
              <FileOutlined />
            </>
          )
        }
      />
    );
  });
};

// Functional component for Root Action
const RootActions = () => {
  const roleUser = useRecoilState(RoleAccessAtom); //global state for getting logged-in user role access data
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //global state for changing header
  const history = useNavigate(); //react-router-dom navigate method
  let selected = false; //selected tree node

  // useState hooks used in this component
  const [sortedColumn, setSortedColumn] = useState(null); //state for column sort indicator in table
  const [sortOrder, setSortOrder] = useState(null); //state for column sort order(asc/des) indicator in table
  const [createModel, setCreateModel] = useState(
    localStorage.getItem("wf-create") ? true : false
  ); //state for create root action confirmation modal open/close
  const [loader, setLoader] = useState(false); //table loader
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom); //supplier tree data from global state
  const [expandedKeys, setExpandedKeys] = useState([]); //expand tree
  const [pathId, setPathId] = useState(["home"]); //selected node path id
  const [alignedData, setAlignedData] = useState([]); //supplier data aligned
  const [flattenedData, setFlattenedData] = useState([]); //flaten supplier tree data
  const [tableData, setTableData] = useState([]); //root action table data
  const [responseData, setResponseData] = useState([]); //tree data
  const [finalPath, setFinalPath] = useState([
    { id: "home", name: "home", type: "" },
  ]); //selected tree node path
  const [selectedItem, setSelectedItem] = useState(null); //selected node in tree
  const [selectedContract, setSelectedContract] = useState(null); //selected contract in tree
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); // root action table pagination
  // function for rooot action table sorting
  const isColumnSorted = (column) => sortedColumn === column;

  useEffect(() => {
    document.title = "Root Action | Judo";
    sessionStorage.setItem("Action", "AllAction");
  }, []);

  useEffect(() => {
    console.log("selectedContract", selectedContract);
  }, [selectedContract]);

  useEffect(() => {
    setSelectedContract(null);
    setFinalPath([{ id: "home", name: "home", type: "" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
      let newData = [...supplierTreeData];
      setFlattenedData(flat(newData));
      setAlignedData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  // function for list all root actions
  const getAllAction = (page, limit) => {
    setLoader(true);
    getAllActionPaginationApi(page, limit).then((res) => {
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: item.action_name,
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("Action") === "ContractAction") {
      let ContractData = JSON.parse(sessionStorage.getItem("ContractAction"));
      getContractAction(
        ContractData.page,
        ContractData.limit,
        ContractData.contractId,
        ContractData.pathOnModify,
        ContractData.selectedContractDetail
      );
    } else if (sessionStorage.getItem("Action") === "SupplierAction") {
      let SupplierData = JSON.parse(sessionStorage.getItem("SupplierAction"));
      getSupplierAction(
        SupplierData && SupplierData.page ? SupplierData.page : 1,
        SupplierData && SupplierData.limit ? SupplierData.limit : 10,
        SupplierData && SupplierData.supplierId ? SupplierData.supplierId : null
      );
    } else if (!selectedContract) {
      getAllAction(1, 20);
      let actionData = {
        page: 1,
        limit: 20,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    }
  }, []);

  useEffect(() => {
    if (!selectedContract) {
      getAllAction(1, 20);
      let actionData = {
        page: 1,
        limit: 20,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    }
  }, [selectedContract]);

  // root action table columns
  const columns = [
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Action Name
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("action_name") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "action_name",
      align: "left",
     // sorter: (a, b) =>
      //   a.action_name.props.children.localeCompare(
      //     b.action_name.props.children
      //   ),
      sorter: (a, b) => a.action_name.localeCompare(b.action_name),
      sortOrder: isColumnSorted("action_name") ? sortOrder : false,
      show: true,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Action Status
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("action_status") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "action_status",
      align: "left",
      sorter: (a, b) => a.action_status.localeCompare(b.action_status),
      sortOrder: isColumnSorted("action_status") ? sortOrder : false,
      show: true,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            Status Level
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("status_level") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "status_level",
      align: "left",
      sorter: (a, b) => a.status_level.localeCompare(b.status_level),
      sortOrder: isColumnSorted("status_level") ? sortOrder : false,
      show: true,
    },
    {
      title: (
        <Row align="middle" type="flex">
          <Col span={22} className="sorted-title">
            <p>Action stats </p>
            <p>(TD-OP-OD-UP)</p>
          </Col>
          <Col span={2} align="right">
            {isColumnSorted("action_stats") && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      ),
      dataIndex: "action_stats",
      align: "left",
      sorter: (a, b) => a.action_stats.localeCompare(b.action_stats),
      sortOrder: isColumnSorted("action_stats") ? sortOrder : false,
      show: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      show:
        (roleUser[0] &&
          (!roleUser[0].page_access.action_management.root_action.view ||
            !roleUser[0].page_access.action_management.root_action.create)) ||
        (selectedContract && selectedContract.type === "supplier") ||
        !selectedContract
          ? false
          : true,
    },
  ];

  const colToShow = columns.filter((col) => col.show);

  // function for flatten supplier tree data
  const flat = (array) => {
    let result = [];
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };

  // secondary function for set path of selected node
  const getPath = (model, id) => {
    let path,
      item = {
        id: model._id,
        name: model.contract_name ? model.contract_name : model.supplier_name,
        type: model.contract_type ? model.contract_type : "Supplier",
      };

    if (!model || typeof model !== "object") return;

    if (model._id === id) return [item];

    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  // primary function for set path of selected node
  const getTopPath = (currentId) => {
    if (currentId !== "home") {
      responseData.some((res) => {
        const pathVal = getPath(res, currentId);
        if (pathVal) {
          setFinalPath([{ id: "home", name: "home", type: "" }, ...pathVal]);
          return pathVal;
        }
        return false;
      });
    } else {
      const finalP = [{ id: "home", name: "home", type: "" }];
      setFinalPath(finalP);
    }
  };

  // function for handle sorting in root action table
  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  // function for handle tree expand
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  // on select tree node
  const onSelect = (e, selectedKeys) => {
    setPathId([...pathId, selectedKeys._id]);
    getTopPath(selectedKeys._id);
    let nestArr = recursivelyFindKeyValue("id", selectedKeys._id, alignedData);
    let obj = nestArr.containingArray.find((o) => o._id === selectedKeys._id);
    setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
    if (selectedKeys.contract_name) {
      let selectedContractDetail = {
        id: selectedKeys._id,
        type: selectedKeys.contract_type,
        supplier: selectedKeys.supplier_id,
      };
      setSelectedContract(selectedContractDetail);
      let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
      if (selectedKeys._id !== "home") {
        responseData.some((res) => {
          const pathVal = getPath(res, selectedKeys._id);
          if (pathVal) {
            finalPathOnModify = [...finalPathOnModify, ...pathVal];
          }
        });
      }
      // set table data regards selected node
      getContractAction(
        1,
        10,
        selectedKeys._id,
        finalPathOnModify,
        selectedContractDetail
      );
      let ContractActionData = {
        page: 1,
        limit: 10,
        contractId: selectedKeys._id,
        pathOnModify: finalPathOnModify,
        selectedContractDetail: selectedContractDetail,
      };
      sessionStorage.setItem("Action", "ContractAction");
      sessionStorage.setItem(
        "ContractAction",
        JSON.stringify(ContractActionData)
      );
    } else {
      let selectedContractDetail = {
        id: selectedKeys._id,
        type: "supplier",
      };
      setSelectedContract(selectedContractDetail);
      getSupplierAction(1, 10, selectedKeys._id);
      let SupplierActionData = {
        page: 1,
        limit: 10,
        supplierId: selectedKeys._id,
      };
      sessionStorage.setItem("Action", "SupplierAction");
      sessionStorage.setItem(
        "SupplierAction",
        JSON.stringify(SupplierActionData)
      );
    }
  };

  // on select node in breadcrumb
  const onSelectBreadcrumb = (selectedKeys) => {
    if (selectedKeys === null) {
      selectedKeys = "home";
      setSelectedContract(null);
    }

    if (selectedKeys !== "home") {
      setPathId((prevState) => [...prevState, selectedKeys]);
      getTopPath(selectedKeys);

      let nestArr = recursivelyFindKeyValue("id", selectedKeys, responseData);
      let obj = nestArr.containingArray.find((o) => o._id === selectedKeys);
      setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
      if (selectedKeys.split("_").length === 4) {
        let selectedContractDetail = {
          id: selectedKeys,
          type: obj.contract_type,
          supplier: obj.supplier_id,
        };
        setSelectedContract(selectedContractDetail);
        let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
        if (selectedKeys !== "home") {
          responseData.some((res) => {
            const pathVal = getPath(res, selectedKeys);
            if (pathVal) {
              finalPathOnModify = [...finalPathOnModify, ...pathVal];
            }
          });
        }
        // set table data regards selected node
        getContractAction(
          1,
          10,
          selectedKeys,
          finalPathOnModify,
          selectedContractDetail
        );
        let ContractActionData = {
          page: 1,
          limit: 10,
          contractId: selectedKeys,
          pathOnModify: finalPathOnModify,
          selectedContractDetail: selectedContractDetail,
        };
        sessionStorage.setItem("Action", "ContractAction");
        sessionStorage.setItem(
          "ContractAction",
          JSON.stringify(ContractActionData)
        );
      } else {
        let selectedContractDetail = {
          id: selectedKeys,
          type: "supplier",
        };
        setSelectedContract(selectedContractDetail);
        getSupplierAction(1, 10, selectedKeys);
        let SupplierActionData = {
          page: 1,
          limit: 10,
          supplierId: selectedKeys,
        };
        sessionStorage.setItem("Action", "SupplierAction");
        sessionStorage.setItem(
          "SupplierAction",
          JSON.stringify(SupplierActionData)
        );
      }
    } else {
      setPathId((prevState) => [...prevState, "home"]);
      getTopPath("home");
      let newData = [...responseData];
      setAlignedData(newData);
      setSelectedContract(null);
    }
  };

  // function for find key of selected item in breadcrumb
  const recursivelyFindKeyValue = (key, keyValue, list) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      for (const key of Object.keys(item)) {
        if (Array.isArray(item[key])) {
          const res = recursivelyFindKeyValue(key, keyValue, item[key]);
          if (res.found === true) return res;
        } else if (item[key] === keyValue) {
          return { found: true, containingArray: list, keyValue: keyValue };
        }
      }
    }

    return { found: false, containingArray: [], keyValue: keyValue };
  };

  // function for list root actions according to selected contract
  const getContractAction = (
    page,
    limit,
    contractId,
    pathOnModify,
    selectedContractDetail
  ) => {
    setLoader(true);
    getActionByContractApi(contractId, page, limit).then((res) => {
      console.log("resss", res);

      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: (
            <span
              style={{
                color:
                  (roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.root_action
                      .view &&
                    (userData[0].id ? userData[0].id : userData[0]._id) ==
                      item.action_owner) ||
                  (userData[0].id ? userData[0].id : userData[0]._id) ==
                    item.action_approver
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  (roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.root_action
                      .view &&
                    (userData[0].id ? userData[0].id : userData[0]._id) ==
                      item.action_owner) ||
                  (userData[0].id ? userData[0].id : userData[0]._id) ==
                    item.action_approver
                    ? "pointer"
                    : "default",
              }}
              onClick={() => {
                const userId = userData[0].id
                  ? userData[0].id
                  : userData[0]._id;

                if (
                  userId == item.action_owner ||
                  (userId == item.action_approver &&
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.action_management.root_action.view)
                ) {
                  setHeaderChanger({
                    header: "actionManagement",
                    headerItem: "1",
                    headerItemName: "actionManagement",
                    stateSetter: { actionData: item, finalPath: pathOnModify },
                  });

                  let actionLog = {
                    ss_id: item._id,
                    ss_name: item.action_name,
                    code: "root_action",
                    author: userId,
                    action: "view",
                    notes: "Viewed Action Management",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(actionLog);
                  history(`/action-management/view-action`, {
                    state: { actionData: item, finalPath: pathOnModify },
                  });
                }
              }}
            >
              {item.action_name}
            </span>
          ),
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .view && (
                    <EyeOutlined
                      title="View"
                      style={
                        (userData[0].id ? userData[0].id : userData[0]._id) !==
                          item.action_owner ||
                        (userData[0].id ? userData[0].id : userData[0]._id) !==
                          item.action_approver
                          ? {
                              fontSize: "20px",
                              color: "var(--color-solid-lightgrey)",
                              pointerEvents: "none",
                              cursor: "not-allowed",
                            }
                          : {
                              fontSize: "20px",
                              color: "var(--color-solid-darkgrey)",
                            }
                      }
                      className="cursor-pointer"
                      onClick={() => {
                        setHeaderChanger({
                          header: "actionManagement",
                          headerItem: "1",
                          headerItemName: "actionManagement",
                          stateSetter: {
                            actionData: item,
                            finalPath: pathOnModify,
                          },
                        });
                        let ssLog = {
                          ss_id: item.id ? item.id : item._id,
                          ss_name: item.action_name,
                          code: "root_action",
                          author: userData[0].id
                            ? userData[0].id
                            : userData[0]._id,
                          action: "view",
                          notes: "Viewed Action Management",
                          updated_on: new Date(),
                        };
                        createActivityLogApi(ssLog);
                        history(`/action-management/view-action`, {
                          state: { actionData: item, finalPath: pathOnModify },
                        });
                      }}
                    />
                  )}
                {(userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_owner ||
                ((userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_approver &&
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .create) ? (
                  <Image
                    title="Edit"
                    src={edit}
                    preview={false}
                    style={{
                      width: "20px",
                    }}
                    className="cursor-pointer"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    // }}
                    onClick={() => {
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "1",
                        headerItemName: "actionManagement",
                        stateSetter: {
                          actionData: item,
                          finalPath: pathOnModify,
                          selectedContract: selectedContractDetail,
                        },
                      });
                      history(`/action-management/edit-action`, {
                        state: {
                          actionData: item,
                          finalPath: pathOnModify,
                          selectedContract: selectedContractDetail,
                        },
                      });
                    }}
                  />
                ) : (
                  <Image
                    title="New Image"
                    src={edit_disable}
                    preview={false}
                    style={{
                      width: "20px",
                      cursor: "default",
                    }}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}

                {(userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_owner ||
                ((userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_approver &&
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .create) ? (
                  <Image
                    title="Clone"
                    src={duplicate}
                    preview={false}
                    style={{ width: "20px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      setHeaderChanger({
                        header: "actionManagement",
                        headerItem: "1",
                        headerItemName: "actionManagement",
                        stateSetter: {
                          actionData: item,
                          finalPath: pathOnModify,
                          selectedContract: selectedContractDetail,
                        },
                      });
                      history(`/action-management/clone-action`, {
                        state: {
                          actionData: item,
                          finalPath: pathOnModify,
                          selectedContract: selectedContractDetail,
                        },
                      });
                    }}
                  />
                ) : (
                  <Image
                    title="New Image"
                    src={clone_disable}
                    preview={false}
                    style={{
                      width: "20px",
                      cursor: "default",
                    }}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              </div>
            </>
          ),
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  // function for list root actions according to selected contract
  const getSupplierAction = (page, limit, supplierId) => {
    setLoader(true);
    getActionBySupplierApi(supplierId, page, limit).then((res) => {
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: item.action_name,
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  // root action table pagination handler
  const onPageChange = (page, limit) => {
    if (selectedContract && selectedContract.type === "supplier") {
      getSupplierAction(page, limit, selectedContract.id);
      let SupplierActionData = {
        page: 1,
        limit: 10,
        supplierId: selectedContract.id,
      };
      sessionStorage.setItem("Action", "SupplierAction");
      sessionStorage.setItem(
        "SupplierAction",
        JSON.stringify(SupplierActionData)
      );
    } else if (!selectedContract) {
      getAllAction(page, limit);
      let actionData = {
        page: page,
        limit: limit,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    } else {
      getContractAction(
        page,
        limit,
        selectedContract.id,
        finalPath,
        selectedContract
      );
      let ContractActionData = {
        page: 1,
        limit: 10,
        contractId: selectedContract.id,
        pathOnModify: finalPath,
        selectedContractDetail: selectedContract,
      };
      sessionStorage.setItem("Action", "ContractAction");
      sessionStorage.setItem(
        "ContractAction",
        JSON.stringify(ContractActionData)
      );
    }
  };

  return (
    <Layout className="header-layout">
      <div id="action-management-id" className="contract-pipeline">
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          {/* Selected node breadcrumb */}
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.action_management.root_action.create
                ? 15
                : 24
            }
            lg={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.action_management.root_action.create
                ? 15
                : 24
            }
            xl={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.action_management.root_action.create
                ? 22
                : 24
            }
          >
            <div className="action-breadcrumb">
              <span>
                <span className="breadcrumb-path">
                  {finalPath &&
                    finalPath.map((item, i) => {
                      return (
                        <>
                          <span
                            key={item.id}
                            onClick={(e) => onSelectBreadcrumb(item.id)}
                            className="path-text"
                          >
                            {item.name === "home" ? (
                              <Image src={account_tree} preview={false} />
                            ) : (
                              item.name
                            )}
                          </span>
                          <span
                            style={{
                              display:
                                i === finalPath.length - 1
                                  ? "none"
                                  : "inline-block",
                            }}
                            className="path-icon"
                          >
                            <RightOutlined />
                          </span>
                        </>
                      );
                    })}
                </span>
              </span>
            </div>
          </Col>

          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.action_management.root_action.create && (
              <Col xl={2} md={3} xs={24} className="vertical-align">
                <div className="">
                  {/* Root action create button */}
                  <CreateButton
                    disabled={
                      sessionStorage.getItem("Action") === "ContractAction"
                        ? false
                        : (selectedContract &&
                            selectedContract.type === "supplier") ||
                          !selectedContract ||
                          !sessionStorage.getItem("Action") === "ContractAction"
                    }
                    style={{
                      color: "white",
                      backgroundColor: "var(--color-solid-darkblue)",
                    }}
                    onClick={() => setCreateModel(true)}
                  />
                </div>

                {/* Confirmation modal for create */}
                <Modal
                  className="create-wf-modal"
                  footer={null}
                  open={createModel}
                  onCancel={() => {
                    setCreateModel(false);
                  }}
                  width={450}
                >
                  <Space align="">
                    <Image src={confirmation} preview={false} />
                    <div>
                      <h3>Confirmation</h3>
                      <span style={{ fontSize: "12px" }}>
                        For creating the Action Instance
                      </span>
                    </div>
                  </Space>
                  {/* Mini map for new creation action level */}
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <div className="mt-5">
                        <b>Creation Flow</b>
                      </div>
                      <div className="creation-flow-div">
                        <Space
                          direction="vertical"
                          size="middle"
                          style={{
                            display: "flex",
                          }}
                        >
                          {finalPath
                            .filter((data) => data.type !== "")
                            .map((item, index) => (
                              <>
                                <div
                                  style={{
                                    marginLeft: index > 0 && `${index * 20}px`,
                                    paddingLeft: "10px",
                                    borderLeft: index > 0 && "2px solid black",
                                  }}
                                  id="flow-btn-bottom"
                                >
                                  <span className="flow-btn-new">
                                    <Image src={FolderImg} preview={false} />
                                    &nbsp;&nbsp;
                                    {item.name}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span style={{ fontSize: "12px" }}>
                                    {item.type}
                                  </span>
                                </div>
                              </>
                            ))}
                          <div
                            style={{
                              marginLeft:
                                finalPath.filter((data) => data.type !== "")
                                  .length > 0 &&
                                `${
                                  finalPath.filter((data) => data.type !== "")
                                    .length * 20
                                }px`,
                              paddingLeft: "10px",
                              borderLeft:
                                finalPath.filter((data) => data.type !== "")
                                  .length > 0 && "2px solid black",
                            }}
                            id="flow-btn-bottom"
                          >
                            <span className="flow-btn-new">
                              <Image src={FolderImg} preview={false} />
                              &nbsp;&nbsp; New Action
                            </span>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 8]} className="mt-10">
                    <Col
                      span={16}
                      offset={4}
                      // className="text-center"
                    >
                      <Row type="flex" align={"middle"}>
                        <Col span={11} offset={1}>
                          {/* button for Cancel root action creation */}
                          <CancelButton
                            onClick={() => {
                              setCreateModel(false);
                            }}
                          />
                        </Col>
                        <Col span={11} offset={1}>
                          {/* create root action button */}
                          <CreateButton
                            onClick={() => {
                              history(`/action-management/root-action/create`, {
                                state: {
                                  finalPath: finalPath,
                                  selectedContract: selectedContract,
                                },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Modal>
              </Col>
            )}
        </Row>

        <Row align="top" type="flex" gutter={[12, 0]} className="mt-10">
          {/* Supplier with contract tree */}
          <Col
            className="p-0"
            xs={24}
            sm={24}
            md={{
              span: 4,
              offset: 1,
            }}
            lg={{
              span: 4,
              offset: 1,
            }}
            xl={{
              span: 4,
              offset: 0,
            }}
          >
            <div
              className="glass-div"
              style={{
                maxHeight: "53vh",
                minHeight: "calc(100vh - 130px)",
              }}
            >
              <Tree
                expandedKeys={expandedKeys}
                onExpand={onExpand}
                onClick={onSelect}
                treeData={alignedData}
                defaultExpandAll
                selectable={false}
                className="system-setup-table"
                showIcon
                titleRender={(node) => {
                  const item = flattenedData.find(
                    (node1) => node1._id === node._id
                  );
                  if (selectedItem) {
                    if (selectedItem.supplier_name == item.supplier_name) {
                      selected = true;
                    } else {
                      selected = false;
                    }
                  }
                  return (
                    <div
                      className="title-div"
                      style={{
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <span
                        className={
                          selected ? "tree-item-selected" : "tree-item"
                        }
                      >
                        {item.contract_name
                          ? item.contract_name
                          : item.supplier_name}
                      </span>
                    </div>
                  );
                }}
                icon={(node) => {
                  return node.data.contract_type === "Supplier" ? (
                    <>
                      <Image src={FolderImg} preview={false} width={10} />
                    </>
                  ) : node.data.contract_type === "MSA" ? (
                    <>
                      <span>
                        <Image
                          src={frameworkAgreement}
                          preview={false}
                          width={15}
                        />
                      </span>
                    </>
                  ) : node.data.contract_type === "Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : node.data.contract_type === "Sub-Supplement" ? (
                    <>
                      <span>
                        <Image src={supplement} preview={false} width={20} />
                      </span>
                    </>
                  ) : node.data.contract_type === "SOW" ? (
                    <>
                      <span>
                        <Image src={sow} preview={false} width={20} />
                      </span>
                    </>
                  ) : node.data.contract_type === "CCN" ? (
                    <>
                      <span>
                        <Image src={ccn} preview={false} width={20} />
                      </span>
                    </>
                  ) : (
                    <>
                      <FileOutlined />
                    </>
                  );
                }}
                switcherIcon={<DownOutlined />}
              >
                {renderTreeNodes(alignedData)}
              </Tree>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={{ span: 18, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xl={{ span: 20, offset: 0 }}
            style={{ minHeight: "calc(100vh - 150px)" }}
          >
            <>
              {/* Root Action list table for selected node */}
              <Table
                onChange={handleTableChange}
                dataSource={tableData}
                loading={loader}
                rowClassName={() => "contract-tree-table-row"}
                columns={colToShow}
                showSorterTooltip={false}
                bordered
                size="large "
                scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 240px)" }}
                pagination={false}
                className="system-setup-table"
                id="action-table"
              />

              {/* table pagination */}
              <div className="float-right mb-15 mt-15">
                <Pagination
                  defaultPageSize={paginateData.limit}
                  responsive
                  current={paginateData.pageNo}
                  total={paginateData.total}
                  showSizeChanger
                  onShowSizeChange={onPageChange}
                  onChange={onPageChange}
                />
              </div>
            </>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

// export Root Action Component
export default RootActions;
